// import { fetchData } from '../utils/fetchData';
// import { downloadExcel } from '../utils/downloadExcel';

// const DownloadExcelButton = () => {
//   const handleDownload = async () => {
//     try {
//       const data = await fetchData();
//       downloadExcel(data);
//     } catch (error) {
//       console.error('Error downloading Excel:', error);
//     }
//   };

//   return <button onClick={handleDownload}>Download Excel</button>;
// };

// export default DownloadExcelButton;

// src/components/DownloadExcelButton.js
import React, { useState } from 'react';
import { downloadAllDataByLgaExcel, downloadAllDataExcel, downloadExcel } from '../utils/downloadExcel';
// import { adminService } from "../services/admin/admin.service";

const DownloadExcelButton = (applicantData) => {
    const [lgaOfOrigin, setLgaOfOrigin] = useState('');
    const [disbursement, setDisbursement] = useState(false);


    // const fetchData = async () => {
    //     adminService.getAllSelectedStaff().then((result) => {
    //         if (Array.isArray(result?.data?.data)) {
    //             return result.data.data;
    //             // setData(result.data.data); // Update data with the fetched data array
    //         } else {
    //             console.error('Data structure is not as expected or empty.');

    //         } 
    //     }).catch((error) => {
    //         console.error('Error fetching applicants data:', error);
    //     })
    // };


    const handleDownload = async (type) => {
        const data = applicantData;
        if (type === "filtered") {
            try {
                downloadExcel(data, lgaOfOrigin, disbursement);
            } catch (error) {
                console.error('Error downloading Excel:', error);
            }
            return;
        };

        if (type === "unfilteredFilterByLga") {
            try {
                downloadAllDataByLgaExcel(data, lgaOfOrigin);
            } catch (error) {
                console.error('Error downloading Excel:', error);
            }
            return
        };

        try {
            downloadAllDataExcel(data);
        } catch (error) {
            console.error('Error downloading Excel:', error);
        }

    }


    return (
        <div className="flex flex-col space-y-4 p-4 bg-gray-100 rounded-lg shadow-md">
            <h1 className="text-xl font-bold">Download Excel</h1>
            <div className="flex flex-row flex-wrap">
                <div className="p-4">
                    <h1 className="text-lg font-bold">Filter by LGA and Disbursement</h1>
                    <input
                        type="text"
                        value={lgaOfOrigin}
                        onChange={(e) => setLgaOfOrigin(e.target.value)}
                        placeholder="LGA of Origin"
                        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-2/4"
                    />
                    <select
                        value={disbursement}
                        onChange={(e) => setDisbursement(e.target.value === 'true')}
                        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-2/4"
                    >
                        <option value="false">Not Disbursed</option>
                        <option value="true">Disbursed</option>
                    </select>
                    <button
                        onClick={() => handleDownload("filtered")}
                        className="m-2 px-8 py-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Download Excel
                    </button>
                </div>
                <div className="p-4">
                    <h1 className="text-lg font-bold">Filter by LGA and Disbursement</h1>
                    <input
                        type="text"
                        value={lgaOfOrigin}
                        onChange={(e) => setLgaOfOrigin(e.target.value)}
                        placeholder="LGA of Origin"
                        className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-2/4"
                    />

                    <button
                        onClick={() => handleDownload("unfilteredFilterByLga")}
                        className="px-8 m-2 py-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Download disbursed By LGA
                    </button>
                </div>
                <div className="">

                    <button
                        onClick={() => handleDownload("unfiltered")}
                        className="px-8 py-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Download Disbursed Full Data
                    </button>
                </div>
            </div>



        </div>
    );
};

export default DownloadExcelButton;
