import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const downloadAllDataExcel = (data, filename = 'disbursed.xlsx') => {

    if (!Array.isArray(data["applicantData"])) {
        console.error('Data is not an array:', data);
        return;
    }
    // Filter the data
    const filteredData = data["applicantData"].filter(item => item.disbursement === true);


    // Create a new workbook and a new worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Write the workbook to a binary string
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Save the file using FileSaver
    saveAs(blob, filename);
};

export const downloadAllDataByLgaExcel = (data, lga, filename = 'disbursed.xlsx') => {

    if (!Array.isArray(data["applicantData"])) {
        console.error('Data is not an array:', data);
        return;
    }
    // Filter the data
    const filteredData = data["applicantData"].filter(item => item.lgaOrigin.toLowerCase() === lga.toLowerCase() && item.disbursement === true);


    // Create a new workbook and a new worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Write the workbook to a binary string
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Save the file using FileSaver
    saveAs(blob, filename);
};



export const downloadExcel = (data, lgaOfOrigin, disbursement, filename = 'data.xlsx') => {

    if (!Array.isArray(data["applicantData"])) {
        console.error('Data is not an array:', data);
        return;
    }
    // Filter the data
    const filteredData = data["applicantData"].filter(item => item.lgaOrigin.toLowerCase() === lgaOfOrigin.toLowerCase() && item.disbursement === disbursement);

    // Create a new workbook and a new worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Write the workbook to a binary string
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Save the file using FileSaver
    saveAs(blob, filename);
};

// Helper function to convert a string to an ArrayBuffer
const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
};
