// import React from 'react'

// import LatestTransactionTable from "./LatestTransactionTable"


const DashboardBody = () => {
  return (
    <div>
       
        {/* <LatestTransactionTable /> */}
    </div>
  )
}

export default DashboardBody