import React, { useState, useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import AuthLayout from './layouts/authLayout';
// import MainLayout from './layouts/mainLayout';
import Home from './pages/Home';
import Apply from "./pages/forms/Apply";
import Verify from "./pages/forms/Verify";
import NotFound from "./pages/404/NotFound";
import ProtectedRoute from './utils/routes/ProtectedRoute';
import ConditionalRoute from './utils/routes/ConditionalRoute';
import routes from './utils/routes/routes';
import Login from './pages/admin/authentication/login';
import SessionTimeout from './SessionTimeout'
import { useNavigate } from 'react-router-dom';
import { adminService } from "./services/admin/admin.service";
import moment from "moment";
import VerifiedApplication from "./pages/VerifiedApplication";

function App() {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('adminToken');
    localStorage.removeItem('user');
    navigate('/login');
  };
  const [portalData, setPortalData] = useState({});


  const getAllReports = () => {

    adminService
      .getPortal()
      .then((result) => {
        if (result?.data) {
          const data = result.data.data;
          // Convert Unix timestamps to moment objects
          data.openTime = moment.unix(data.openTime);
          data.closeTime = moment.unix(data.closeTime);

          // Calculate remaining time in milliseconds
          const currentTime = moment();


          setPortalData(data);


          // Check if current time is between openTime and closeTime
          if (currentTime.isBetween(data.openTime, data.closeTime)) {

          } else {

          }
        } else {
          console.error("Data structure is not as expected or empty.");
          setPortalData({});

        }
      })
      .catch((error) => {
        console.error("Error fetching portal data:", error);
        setPortalData({});

      })
      .finally(() => {

      });
  };

  useEffect(() => {
    getAllReports();

    // Refresh data every minute
    const interval = setInterval(() => {
      getAllReports();
    }, 60000); // Refresh every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AuthLayout><Home /></AuthLayout>} />
        <Route path="/apply-loan" element={
          <ConditionalRoute condition={portalData.open === 'yes'} redirectTo="/">
            <AuthLayout><Apply /></AuthLayout>
          </ConditionalRoute>
        } />
        <Route path="/verify-selection" element={
          <AuthLayout><Verify /></AuthLayout>

        } />
        <Route path="/verified-application" element={<AuthLayout><VerifiedApplication /></AuthLayout>} />
        <Route path="/login" element={<AuthLayout><Login /></AuthLayout>} />
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <ProtectedRoute>
                <route.component />
              </ProtectedRoute>
            }
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <SessionTimeout timeoutInMinutes={10} onTimeout={logout} />
    </div>
  );
}

export default App;
