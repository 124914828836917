/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"; //  { useState, useEffect }
import Button from "../components/controls/Button";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { adminService } from "../services/admin/admin.service";
import ApprovalCheckSelection from "../components/ApprovalCheckSelection";
const Home = () => {
  const navigate = useNavigate();
  const [portalData, setPortalData] = useState({});
  const [remainingTime, setRemainingTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isApplicationClosed, setIsApplicationClosed] = useState(false);

  const getAllReports = () => {
    setIsLoading(true);
    adminService
      .getPortal()
      .then((result) => {
        if (result?.data) {
          const data = result.data.data;
          // Convert Unix timestamps to moment objects
          data.openTime = moment.unix(data.openTime);
          data.closeTime = moment.unix(data.closeTime);

          // Calculate remaining time in milliseconds
          const currentTime = moment();
          const remaining = data.closeTime.diff(currentTime);

          setPortalData(data);
          setRemainingTime(remaining);
          // Convert Unix timestamps to moment objects
          // data.openTime = moment.unix(data.openTime).tz("Africa/Lagos");
          // data.closeTime = moment.unix(data.closeTime).tz("Africa/Lagos");

          // // Calculate remaining time in milliseconds
          // const currentTime = moment().tz("Africa/Lagos");
          // const remaining = data.closeTime.diff(currentTime);

          // setPortalData(data);
          // setRemainingTime(remaining);

          // Check if current time is between openTime and closeTime
          if (currentTime.isBetween(data.openTime, data.closeTime)) {
            setIsApplicationClosed(false);
          } else {
            setIsApplicationClosed(true);
          }
        } else {
          console.error("Data structure is not as expected or empty.");
          setPortalData({});
          setRemainingTime(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching portal data:", error);
        setPortalData({});
        setRemainingTime(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllReports();


  }, []);

  // Format remaining time for display
  const formatRemainingTime = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="w-full h-full py-6  md:px-4">
      {/* {portalData.openTime.format("D MMMM, YYYY h:mma")}
      {portalData.closeTime.format("D MMMM, YYYY h:mma")} */}
      <div className="flex flex-col space-y-3 text-center">
        <p className="text-2xl font-bold text-dark">
          Technical Committee on Jigawa Worker Agricultural Support Programme
        </p>
        < ApprovalCheckSelection />
        {/* <p className="text-xl font-bold text-dark">
          Call for Expression of Interest
        </p>
        <p>
          Agriculture remains the backbone of Jigawa State's economy, with a
          significant portion of the population relying on farming for their
          livelihoods. Despite this, many government workers in the state face
          challenges in achieving food security for their families due to food
          prices inflation, limited resources and access to agricultural inputs.
          Recognizing the critical role that food security plays in the overall
          well-being and productivity of its workforce, the Jigawa State
          Government has initiated the Jigawa Worker Agricultural Support
          Programme. The programme is designed to empower government workers to
          achieve self-sufficiency in food production, ensuring that their
          families have access to a steady supply of nutritious food. By
          providing targeted support in the form of loans for inputs and
          operational costs, the programme aims to facilitate the cultivation of
          key crops such as rice, sesame, millet, and sorghum. This not only
          enhances the personal food security of the workers but also
          contributes to the state's broader goal of increasing agricultural
          productivity and surplus.
        </p>
        <p className="text-xl font-bold text-dark">
          Objectives of the Programme
        </p>
        <ul>
          <li>
            To support government workers in Jigawa State with agricultural
            inputs and operational cost loans.
          </li>
          <li>
            To promote the cultivation of rice, sesame, millet, and sorghum
            among government workers.
          </li>
          <li>
            To enhance food security and increase agricultural productivity in
            Jigawa State.
          </li>
          <li>
            To provide financial assistance that helps government workers engage
            in profitable agricultural activities without financial strain.
          </li>
          <li>
            To support Jigawa workers in achieving personal food security and
            contributing to the state's overall food security goals.
          </li>
        </ul>
        <p className="text-xl font-bold text-dark">Type of Support</p>
        <p>
          The programme will offer crop production inputs loans to deserving
          Jigawa State and Local Government employees for the cultivation of the
          following priority crops:
        </p>
        <ul>
          <li>Rice</li>
          <li>Millet</li>
          <li>Sorghum</li>
          <li>Sesame</li>
        </ul>
        <p className="text-xl font-bold text-dark">Eligibility Criteria</p>
        <p>
          To be eligible for the Jigawa Worker Agricultural Support Programme,
          applicants must meet the following criteria:
        </p>
        <ul>
          <li>Must be a Jigawa State Government or Local Government worker.</li>
          <li>
            Must not have a heavy debt burden exceeding 1/3 of their monthly
            salary.
          </li>
          <li>Must accept the terms and conditions of the programmme.</li>
          <li>Must apply online through the approved application portal. </li>
          <li>
            To support Jigawa workers in achieving personal food security and
            contributing to the state's overall food security goals.
          </li>
        </ul>
        <p className="text-xl font-bold text-dark">Repayment:</p>
        <p>The loan will be repaid at the end of one the cropping cyle.</p>
        <p className="text-xl font-bold text-dark">Application Details</p>
        <p>
          Interested applicants are invited to submit their expressions of
          interest online through the provided portal.
        </p>
        {portalData.open === "yes" && !isApplicationClosed ? (
          <span className="text-md font-bold text-primary px-2">
            <Link to="apply-loan">Apply Now</Link>
          </span>
        ) : (
          <p className="text-md font-bold text-dark">
            Application is Close...{" "}
          </p>
        )}

        <p>
          Opening Date:{" "}
          <span className="text-md font-bold text-dark">
            Thursday, 20th June, 2024
          </span>
        </p>
        <p>
          Closing Date:{" "}
          <span className="text-md font-bold text-dark">
            Tuesday, 25th June, 2024
          </span>
        </p>
        <p className="">
          For help contact{" "}
          <span className="text-lg font-bold text-primary">08064356660</span>
        </p>
        <p>
          For more information, please visit{" "}
          <span className="text-lg font-bold text-primary">
            https://www.jigawaworkeragricsupportschemes.com/
          </span>{" "}
          or contact the Jigawa State Ministry of Agriculture.
        </p>
      </div>

      <div className="flex flex-col space-y-4 py-4">
        <div className="flex items-center justify-center px-12">
          {portalData.open === "yes" && !isApplicationClosed ? (
            <Button
              type="button"
              onClick={() => {
                navigate("/apply-loan");
              }}
              bgColor="bg-primary"
              textColor="text-white"
            >
              <span className="flex flex-row space-x-3">
                <span>Apply</span>
              </span>
            </Button>
          ) : (
            <>
              {portalData.open === "close" ? (
                <p className="text-md">
                  Application will Open in :{" "}
                  <span className="text-2xl text-primary font-sembold">
                    {formatRemainingTime(remainingTime)}
                  </span>
                </p>
              ) : null}
            </>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Home;
