import React, { useState, useEffect } from "react";
import { adminService } from "../../../../services/admin/admin.service";
import { Link } from "react-router-dom";
import TableOption from "../../../../components/modal/TableOption";
// import CustomModal from "../../../../components/modal/CustomModal";
import { SlOptionsVertical } from "react-icons/sl";
import InputField from "../../../../components/controls/InputField";
import { toast } from "react-toastify";
// // import Loading from "../../../components/loader/Loading";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../../components/loader/Loading";
import { FiSearch } from "react-icons/fi";
import { MdOutlineFileDownload } from "react-icons/md";
import * as XLSX from "xlsx";
// import Verify from "../forms/Verify";
// import Production from "../forms/Production";
// import Payment from "../forms/Payment";
// import Collection from "../forms/Collection";
const ReportTable = () => {
  const [data, setTableState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoading, setIsLoading] = useState(false); // Set initial state as an empty array
  const getAllReports = () => {
    setIsLoading(true);
    adminService
      .getAllApplications()
      .then((result) => {
        if (Array.isArray(result?.data?.data)) {
          console.log(result?.data.data);
          setTableState(result.data.data); // Update tableState with the fetched data array
        } else {
          console.error("Data structure is not as expected or empty.");
          setTableState([]); // Set an empty array if data structure is not as expected or empty
        }
      })
      .catch((error) => {
        console.error("Error fetching employee data:", error);
        setTableState([]); // Set an empty array or handle the error case accordingly
      }).finally(()=>{
        setIsLoading(false)
      });
  };

  // const [details, setDetails] = useState({});
  // const [pay, setPay] = useState(false);
  // const [collect, setCollect] = useState(false);
  // const [produce, setProduce] = useState(false);

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleModalOpen = (id) => {
  //   const selectedReport = data.find((report) => report.id === id);
  //   if (selectedReport) {
  //     setDetails(selectedReport);
  //     setIsModalOpen(true);
  //   }
  // };
  // const handleModalClose = () => setIsModalOpen(false);

  // const openCModal =(id)=>{
  //   const selectedReport = data.find(report => report.id === id);
  //   if (selectedReport) {
  //     setDetails(selectedReport);
  //   setCollect(true)
  //   }
  // }
  // const closeCModal = ()=> setCollect(false);

  // const openPayModal =(id)=>{
  //   const selectedReport = data.find(report => report.id === id);
  //   if (selectedReport) {
  //     setDetails(selectedReport);
  //   setPay(true)
  //   }
  // }
  // const closePayModal =()=> setPay(false);

  // const openPrModal =(id)=>{
  //   const selectedReport = data.find(report => report.id === id);
  //   if (selectedReport) {
  //     setDetails(selectedReport);
  //   setProduce(true)
  //   }
  // }
  // const closePrModal =()=> setProduce(false);
  
  const getTransactionsByRef = async (value) => {
    try {
      setIsLoading(true);
      const result = await adminService.getApplicationByPsn(value);
      if (
        result &&
        result.data &&
        result.data.success === true &&
        result.data.data
      ) {
        setTableState([result.data.data]);
      } else {
        toast.error("Error fetching transactions.");
        setTableState([]);
      }
    } catch (error) {
      console.error(error);
      setTableState([]);
    } finally {
      setIsLoading(false);
    }
  };
  const [ref, setRef] = useState("");
  const handleReferenceChange = (event) => {
    const value = event.target.value;
    setRef(value);
    if (value === "") {
      // If the search value is empty, fetch all transactions
      getAllReports();
    } else {
      // Otherwise, fetch transactions by reference
      getTransactionsByRef(value);
    }
  };
useEffect(()=>{
  getAllReports();
}, [])
const exportToExcel = () => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");
  XLSX.writeFile(workbook, "Reports.xlsx");
};
  return (
    <>
      <div className="py-4 responsive overflow-x-auto">
      <div className="flex flex-col md:flex-row md:justify-between">
        <div className="flex flex-col space-y-3 space-x-0 md:space-y-0 lg:space-y-0 md:flex-row md:space-x-5 justify-end">
          <div>
            <InputField
              type="text"
              // label="Search"
              name="ref"
              placeholder="Search by reference"
              onChange={handleReferenceChange}
              value={ref}
              rightIcon={<FiSearch size={20} />}
            />
          </div>
          <div className="">
            <div
             onClick={exportToExcel}
              className="bg-lig  p-2 flex flex-row space-x-2 cursor-pointer mt-3 rounded-lg"
            >
            <p>Download</p>
              <MdOutlineFileDownload size={20} className="text-dark" />
            </div>
           
          </div>
          
         
        </div>
        </div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                SN
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Applicant Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Applicant PSN NO
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Applicant Phone No
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Applicant Email Address
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
               Gender
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Salary Bank
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Account Number
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                BVN
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                NIN
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Grade Level
              </th>

              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                MDA
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Appointment Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                LGA Of Origin
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Crop Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Farm Location
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Farm Ward
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Community
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Farm LGA
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date Applied
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          {isLoading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
           <tbody className="bg-white divide-y divide-gray-200">
            {data.map((report, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {report.fullname}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{report.psn}</td>
                <td className="px-6 py-4 whitespace-nowrap">{report.phone}</td>
                <td className="px-6 py-4 whitespace-nowrap">{report.email}</td>
                <td className="px-6 py-4 whitespace-nowrap">{report.gender}</td>
                <td className="px-6 py-4 whitespace-nowrap">{report.bank}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {report.accountNo}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">{report.bvn}</td>
                <td className="px-6 py-4 whitespace-nowrap">{report.nin}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {report.gradeLevel}
                </td>

                <td className="px-6 py-4 whitespace-nowrap">{report.mda}</td>
                <td className="px-6 py-4 whitespace-nowrap">{report.appointmentType}</td>

                <td className="px-6 py-4 whitespace-nowrap">{report.lga}</td>
                <td className="px-6 py-4 whitespace-nowrap">{report.cropType}</td>

                <td className="px-6 py-4 whitespace-nowrap">
                  {report.farmLoc}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {report.farmWard}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {report.community}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {report.farmLga}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {report.updatedAt}
                </td>
                <td>
                  <div>
                    <TableOption
                      index={0}
                      icon={
                        <SlOptionsVertical size={20} className="text-primary" />
                      }
                    >
                      <ul className="flex flex-col space-y-1 py-2 capitalize">
                        <li className="">
                          <Link to={`/report/${report.id}`}>
                            <button
                              //  onClick={() => {handleModalOpen(report.id)}}

                              className="w-full p-2 hover:bg-primary hover:text-white"
                            >
                              Veiw Details
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </TableOption>
                  </div>
                </td>
              </tr>
            ))}
            {data.length === 0 && (
              <tr>
                <td
                  colSpan="1"
                  className="px-6 py-4 whitespace-nowrap text-center text-gray-500"
                >
                  No report found
                </td>
              </tr>
            )}
          </tbody>
          </> )}
         
        </table>
      </div>
      {/* <CustomModal title="Verify Document" open={isModalOpen} onClose={handleModalClose}  width={400}
                  height={250}>
          <Verify data={details} onClose={handleModalClose} />
        </CustomModal>
   
    <CustomModal title="Update Collection Status" open={collect} onClose={closeCModal} width={400}
                  height={250}>
      <Collection data={details} onClose={closeCModal} />
      </CustomModal>
      <CustomModal title="Update Production Sttaus" open={produce} onClose={closePrModal} width={400}
                  height={250}>
      <Production data={details} onClose={closePrModal} />
      </CustomModal>
      <CustomModal title="Update Payment Status" open={pay} onClose={closePayModal} width={400}
                  height={250}>
      <Payment data={details} onClose={closePayModal} />
      </CustomModal> */}
    </>
  );
};

export default ReportTable;
