/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { adminService } from '../services/admin/admin.service';
import { FaTimesCircle } from 'react-icons/fa';
import ReactToPrint from 'react-to-print';
import Certificate from '../components/Certificate';
import { toast } from "react-toastify";
import PlaCertifcate from '../components/PlaCertificate';

import { applyService } from "../services/apply/apply.service";


function VerifiedApplication() {
    const certificateRef = useRef();
    const [portalData, setPortalData] = useState({});
    const [error, setError] = useState("");
    const [applicantType, setApplicantType] = useState('');
    const [applicant, setApplicant] = useState({
        id: "null",
        name: "null",
        psn: "null",
        gradeLevel: "null",
        cropType: "null",
        phoneNumber: "null",
        email: "null",
        nin: "null",
        bank: "null",
        accountNumber: "null",
        mdaLga: "null",
        lgaOrigin: "null",
        farmLga: "null",
        farmLocation: "null",
        issueDate: "null",
    });
    // Inside your component
    const location = useLocation();
    const { psnValue, plaValue } = location.state || {};


    const getStaff = () => {
        applyService
            .getApprovedStaffByPsn(psnValue)
            .then((result) => {
                if (result && result.data.success) {
                    setApplicant({
                        id: result.data.data.id,
                        name: result.data.data.fullname,
                        psn: result.data.data.psn,
                        gradeLevel: result.data.data.gradeLevel,
                        cropType: result.data.data.cropType,
                        phoneNumber: result.data.data.phoneNumber,
                        email: result.data.data.email,
                        nin: result.data.data.nin,
                        bank: result.data.data.bank,
                        accountNumber: result.data.data.accountNo,
                        mdaLga: result.data.data.mda,
                        lgaOrigin: result.data.data.lgaOrigin,
                        farmLga: result.data.data.farmLga,
                        farmLocation: result.data.data.farmLocation,
                        issueDate: result.data.data.updatedAt,
                    })

                } else {
                    setError("Employee Not Approved...");
                    toast.error("Employee Not Approved...");
                }
            })
            .catch((error) => {
                console.error("Error response:", error.response); // Add logging for debugging
                if (error.response && error.response.status === 404) {
                    setError("Employee Not Approved...");
                    toast.error(error.response?.data?.message ?? "Employee Not Approved...");
                } else {
                    toast.error(error.response?.data?.message ?? error?.message);
                }
            })
    };

    const getPlaStaff = () => {
        applyService
            .getPlaStaffByPla(plaValue)
            .then((result) => {
                if (result && result.data.success) {
                    setApplicant({
                        id: result.data.data.id,
                        name: result.data.data.fullname,
                        plaNumber: result.data.data.plaNumber,
                        designation: result.data.data.designation,
                        portfolio: result.data.data.portfolio,
                        cropType: result.data.data.cropType,
                        farmSize: result.data.data.farmSize,
                        phoneNumber: result.data.data.phoneNumber,
                        farmLga: result.data.data.farmLga,
                        farmLocation: result.data.data.farmLga,
                        issueDate: result.data.data.issueDate,
                    })

                } else {
                    setError("PLA Not Approved...");
                    toast.error("PLA Not Approved...");
                }
            })
            .catch((error) => {
                console.error("Error response:", error.response); // Add logging for debugging
                if (error.response && error.response.status === 404) {
                    setError("PLA Not Approved...");
                    toast.error(error.response?.data?.message ?? "PLA Not Approved...");
                } else {
                    toast.error(error.response?.data?.message ?? error?.message);
                }
            })
    };

    const getAllReports = () => {
        adminService
            .getPortal()
            .then((result) => {
                if (result?.data) {
                    const data = result.data.data;
                    // Convert Unix timestamps to moment objects
                    data.openTime = moment.unix(data.openTime);
                    data.closeTime = moment.unix(data.closeTime);

                    // Calculate remaining time in milliseconds
                    const currentTime = moment();
                    const remaining = data.closeTime.diff(currentTime);

                    setPortalData(data);

                } else {
                    console.error("Data structure is not as expected or empty.");
                    setPortalData({});
                    setPortalData({ open: "no" });
                }
            })
            .catch((error) => {
                console.error("Error fetching portal data:", error);
                setPortalData({ open: "no" });
            })
    };

    useEffect(() => {
        getAllReports();
    }, []);

    useEffect(() => {
        if (psnValue) {
            setApplicantType('psn');
            getStaff();
        } else if (plaValue) {
            setApplicantType('pla');
            getPlaStaff();
        }
    }, [psnValue])


    if (portalData.open === "no") {
        return (
            <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
                <p className="font-bold flex items-center">
                    <i className="fas fa-info-circle mr-2"></i>
                    Information
                </p>
                <p>Printing of Certificate is closed at this moment.</p>
            </div>
        )
    }

    if (applicantType === 'pla') {
        return (
            error ? (
                <div className='h-fit min-h-[50vh]'>
                    <div className="error-message flex items-center text-red-600">
                        <FaTimesCircle size={24} className="mr-2" />
                        <span>PLA with provided PLA number not approved</span>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center min-h-screen h-fit p-4 relative">
                    <h1 className="text-3xl font-bold mb-4 text-center">Congratulations, You Were Selected!</h1>
                    <h2 className="text-xl font-bold mb-4 text-center">Print your certificate as it will be required at your redemption center</h2>
                    <div className="mb-8 p-4 border relative border-gray-300 rounded shadow overflow-hidden h-full w-full max-w-4xl">
                        <PlaCertifcate ref={certificateRef} applicant={applicant} />
                    </div>
                    <ReactToPrint
                        trigger={() => <button className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors duration-200 ease-in-out">Print Certificate</button>}
                        content={() => certificateRef.current}
                    />
                </div>

            )
        );
    }


    return (
        error ? (
            <div className='h-fit min-h-[50vh]'>
                <div className="error-message flex items-center text-red-600">
                    <FaTimesCircle size={24} className="mr-2" />
                    <span>Employee with PSN number not approved</span>
                </div>
            </div>
        ) : (
            <div className="flex flex-col items-center justify-center min-h-screen h-fit p-4 relative">
                <h1 className="text-3xl font-bold mb-4 text-center">Congratulations, You Were Selected!</h1>
                <h2 className="text-xl font-bold mb-4 text-center">Print your certificate as it will be required at your redemption center</h2>
                <div className="mb-8 p-4 border relative border-gray-300 rounded shadow overflow-hidden h-full w-full max-w-4xl">
                    <Certificate ref={certificateRef} applicant={applicant} />
                </div>
                <ReactToPrint
                    trigger={() => <button className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors duration-200 ease-in-out">Print Certificate</button>}
                    content={() => certificateRef.current}
                />
            </div>

        )
    );
}

export default VerifiedApplication;
