import { util } from "../../constants/api.constant";
import { http } from "../../constants/http";

let adminService = {
  createStaff: async (body) => {
    try {
      return await http.post(`${util.baseUrl}staff/`, body, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error creating staff:', error);
      throw error;
    }
  },

  createApprovedStaff: async (body) => {
    try {
      return await http.post(`${util.baseUrl}approved/`, body, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error creating staff:', error);
      throw error;
    }
  },
  createPlaStaff: async (body) => {
    try {
      return await http.post(`${util.baseUrl}pla/`, body, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error creating staff:', error);
      throw error;
    }
  },

  updateDisbursement: async (psn, status) => {
    try {
      return await http.put(`${util.baseUrl}approved/${psn}`, { disbursement: status }, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error updating disbursement status:', error);
      throw error;
    }
  },
  updatePlaDisbursement: async (pla, status) => {
    try {
      return await http.put(`${util.baseUrl}pla/${pla}`, { disbursement: status }, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error updating disbursement status:', error);
      throw error;
    }
  },

  createUser: async (body) => {
    try {
      return await http.post(`${util.baseUrl}auth/create`, body, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  },


  upload: async (body) => {
    try {
      return await http.post(`${util.baseUrl}staff/bulks`, body, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error uploading data:', error);
      throw error;
    }
  },
  uploadApprovedApplicants: async (body) => {
    try {
      return await http.post(`${util.baseUrl}approved/bulks`, body, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error uploading data:', error);
      throw error;
    }
  },
  uploadPlaApplicants: async (body) => {
    try {
      return await http.post(`${util.baseUrl}pla/bulks`, body, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error uploading data:', error);
      throw error;
    }
  },
  portal: async (body) => {
    try {
      return await http.put(`${util.baseUrl}portal/`, body, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error uploading data:', error);
      throw error;
    }
  },
  getPortal: async () => {
    try {
      return await http.get(`${util.baseUrl}portal/1`, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error fetching statistics:', error);
      throw error;
    }
  },
  getStatistics: async () => {
    try {
      return await http.get(`${util.baseUrl}apply/counts`, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error fetching statistics:', error);
      throw error;
    }
  },

  getAllStaff: async () => {
    try {
      return await http.get(`${util.baseUrl}staff`, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error fetching all staff:', error);
      throw error;
    }
  },

  getAllSelectedStaff: async () => {
    try {
      return await http.get(`${util.baseUrl}approved`, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error fetching all staff:', error);
      throw error;
    }
  },
  getAllPlaStaff: async () => {
    try {
      return await http.get(`${util.baseUrl}pla`, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error fetching all staff:', error);
      throw error;
    }
  },

  getAllUsers: async () => {
    try {
      return await http.get(`${util.baseUrl}auth/`, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error fetching all users:', error);
      throw error;
    }
  },

  getAllApplications: async () => {
    try {
      return await http.get(`${util.baseUrl}apply`, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error('Error fetching all applications:', error);
      throw error;
    }
  },

  getApplicationById: async (id) => {
    try {
      return await http.get(`${util.baseUrl}apply/${id}`, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error(`Error fetching application with ID ${id}:`, error);
      throw error;
    }
  },
  getApplicationByPsn: async (id) => {
    try {
      return await http.get(`${util.baseUrl}apply/psn/${id}`, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error(`Error fetching application with ID ${id}:`, error);
      throw error;
    }
  },

  deleteStaff: async (id) => {
    try {
      return await http.put(`${util.baseUrl}staff/${id}`, null, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error(`Error deleting staff with ID ${id}:`, error);
      throw error;
    }
  },
  deleteApprovedApplicant: async (id) => {
    try {
      return await http.put(`${util.baseUrl}approved/${id}`, null, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error(`Error deleting staff with ID ${id}:`, error);
      throw error;
    }
  },
  deleteApply: async (id) => {
    try {
      return await http.put(`${util.baseUrl}apply/${id}`, null, {
        headers: util.getAuthorizedHeaders(),
      });
    } catch (error) {
      console.error(`Error deleting application with ID ${id}:`, error);
      throw error;
    }
  },
};

export { adminService };
