import React, { forwardRef } from 'react';
import QRCode from 'qrcode.react';
// import MilletTable from './cropTables/MilletTable';
// import RiceTable from './cropTables/RiceTable';
// import SorghumTable from './cropTables/SorghumTable';
// import SesameTable from './cropTables/SesameTable';
import { MilletTable, RiceTable, SorghumTable, SesameTable } from './CropTable';

const Certificate = forwardRef(({ applicant }, ref) => {

    const renderTable = () => {
        switch (applicant?.cropType.toLowerCase()) {
            case 'millet':
                return <MilletTable />;
            case 'rice':
                return <RiceTable />;
            case 'sorghum':
                return <SorghumTable />;
            case 'sesame':
                return <SesameTable />;
            default:
                return <p>Invalid crop type</p>;
        }
    };


    const qrCodeValue = `
    Name: ${applicant.name}
    PSN: ${applicant.psn}
    Grade Level: ${applicant.gradeLevel}
    Crop Type: ${applicant.cropType}
    Phone Number: ${applicant.phoneNumber}
    Email: ${applicant.email}
    NIN: ${applicant.nin}
    Bank: ${applicant.bank}
    Account Number: ${applicant.accountNumber}
    MDA/LGA: ${applicant.mdaLga}
    LGA of Origin: ${applicant.lgaOrigin}
    Farm LGA: ${applicant.farmLga}
    Farm Location: ${applicant.farmLocation}
    Issued on: ${applicant.issueDate}
    `;
    return (
        <div
            ref={ref}
            className="flex flex-col w-full h-fit relative items-center justify-center p-12"
        >
            <div className='certificate relative  bg-cover bg-center flex flex-col items-center justify-center'>
                <div className="text-center bg-white bg-opacity-80 p-4 rounded-lg shadow-lg relative text-black">
                    <h1 className="text-2xl font-bold mb-4">Technical Committee on Workers Agricultural Support Programme</h1>
                    <p className="text-lg mb-2">This is to certify that</p>
                    <p className="text-2xl font-semibold mb-4">{applicant.name}</p>
                    <div className="text-left grid grid-cols-2 place-content-center">
                        <p>PSN: {applicant.psn}</p>
                        <p>Grade Level: {applicant.gradeLevel}</p>
                        <p>Crop Type: {applicant.cropType}</p>
                        <p>Phone Number: {applicant.phoneNumber}</p>
                        <p>Email: {applicant.email}</p>
                        <p>NIN: {applicant.nin}</p>
                        <p>Bank: {applicant.bank}</p>
                        <p>Account Number: {applicant.accountNumber}</p>
                        <p>MDA/LGA: {applicant.mdaLga}</p>
                        <p>LGA of Origin: {applicant.lgaOrigin}</p>
                        <p>Farm LGA: {applicant.farmLga}</p>
                        <p>Farm Location: {applicant.farmLocation}</p>

                    </div>
                    <h4 className='font-extrabold p-2'>Redemption Center: {applicant.lgaOrigin} </h4>
                    <div className="mt-4">
                        <h5 className='capitalize'>Input and Labour Cost Analysis for {applicant.cropType} Farming Per Hectare</h5>
                        {renderTable()}
                    </div>
                    <p className="mt-4 ml-2 text-base">For Enquiries Contact 08064356660</p>
                    <div className="mt-8">
                        <QRCode value={qrCodeValue} />
                    </div>
                </div>
            </div>

        </div>
    );
});

export default Certificate;
