import React, {useEffect, useState} from 'react'
import { adminService } from "../../../services/admin/admin.service";
import MainCard from "../../../components/cards/MainCard";
import Input from "../../../components/controls/Input";
import SelectField from "../../../components/controls/SelectField";
import moment from 'moment';
import Loading from "../../../components/loader/Loading";
import SubmitButton from "../../../components/controls/SubmitButton";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";


const Index = () => {
    const [portalData, setPortalData] = useState({});
  const [remainingTime, setRemainingTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const getAllReports = () => {
    setIsLoading(true);
    adminService
      .getPortal()
      .then((result) => {
        if (result?.data) {
          const data = result.data.data;

          // Convert Unix timestamps to moment objects
          data.openTime = moment.unix(data.openTime);
          data.closeTime = moment.unix(data.closeTime);

          // Calculate remaining time in milliseconds
          const currentTime = moment();
          const remaining = data.closeTime.diff(currentTime);

          setPortalData(data);
          setRemainingTime(remaining);

        } else {
          console.error("Data structure is not as expected or empty.");
          setPortalData({});
          setRemainingTime(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching portal data:", error);
        setPortalData({});
        setRemainingTime(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllReports();

    // Refresh data every minute
    const interval = setInterval(() => {
      getAllReports();
    }, 60000); // Refresh every minute

    return () => clearInterval(interval);
  }, []);

  // Format remaining time for display
  const formatRemainingTime = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${hours}h ${minutes}m ${seconds}s`;
  };const [input, setInput] = useState({
    open: "",
    openTime: "", // Store as string
    closeTime: "", // Store as string
  });

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setInput((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  };

  const submit = () => {
    setLoading(true);

    // Format dates before sending to backend
    const dataToSend = {
      ...input,
      openTime: moment(input.openTime, "DD MMMM, YYYY h:mmA").unix(),
      closeTime: moment(input.closeTime, "DD MMMM, YYYY h:mmA").unix(),
    };

    adminService
      .portal(dataToSend)
      .then((result) => {
        if (result && result.data.success) {
          toast.success(result.data.message);
          getAllReports();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message ?? error.response.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="">
      <div className="flex flex-col space-y-4 py-6">
        <div className="">
          <h3 className="text-dark text-2xl font-bold capitalize">
            Portal Setttings
          </h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <MainCard>
        <div className="flex flex-col space-y-3">
            
            {isLoading ? (
            <Loading />
          ) : (
            <>
        <p className="text-md">Portal is currently: <span className="text-md text-dark font-sembold">{portalData.open === 'yes' ? 'open' : 'close'}</span></p>
              {portalData.openTime && (
                <p className="text-md">Open Time: <span className="text-md text-dark font-sembold">{portalData.openTime.format('D MMMM, YYYY h:mma')}</span></p>
              )}
              {portalData.closeTime && (
                <p className="text-md">Close Time: <span className="text-md text-dark font-sembold">{portalData.closeTime.format('D MMMM, YYYY h:mma')}</span></p>
              )}
              {remainingTime !== null ? (
                <p className="text-md">Time remaining: <span className="text-md text-dark font-sembold">{formatRemainingTime(remainingTime)}</span></p>
              ) : (
                <p>Time remaining: N/A</p>
              )}
            </>
          )}
        </div>
        </MainCard>
        <MainCard>
<div className="flex flex-col space-y-3 p-4">
    <div>
        <p className="text-dark font-bold text-lg text-center">Open or Close Portal</p>
    </div>
<div>
                  <SelectField
                    name="open"
                    required
                    label="Status"
                    onChange={(e) => {
                      setInput({ ...input, open: e.target.value });
                    }}
                    value={input?.open}
                  >
                    <option value="">Status</option>
                    <option value="yes">Open</option>
                    <option value="no">Close</option>
                  </SelectField>
                </div>
                <div>
                <Input
                  type="text"
                  name="openTime"
                  label="Open Date and Time"
                  placeholder="20 June, 2024 6:00pm"
                  onChange={inputHandler}
                  value={input.openTime}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="closeTime"
                  label="Close Date and Time"
                  placeholder="20 June, 2024 5:50pm"
                  onChange={inputHandler}
                  value={input.closeTime}
                />
              </div>
                <div className="">
                  <SubmitButton
                 
                    onClick={!loading ? submit : null}
                  >
                    {loading ? (
                      <ClipLoader color="#fff" size={30} data-testid="loader" />
                    ) : (
                      "Submit"
                    )}
                  </SubmitButton>
                </div>
</div>
        </MainCard>
        </div>
      </div>
    </div>
  );
};

export default Index;
