import React, { useState } from "react";
import Input from "../../components/controls/Input";
import SelectField from "../../components/controls/SelectField";
import RadioField from "../../components/controls/RadioField";
import SubmitButton from "../../components/controls/SubmitButton";
import { applyService } from "../../services/apply/apply.service";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";
import lgaData from "../../constants/lga.json";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const Apply = () => {
  const [tab, setTab] = useState(1);
  const [error, setError] = useState("");
  const changeTab = (t) => {
    setTab(t);
    setError("");
  };
  const lgas = [
    "Auyo",
    "Babura",
    "Buji",
    "Biriniwa",
    "Birnin Kudu",
    "Dutse",
    "Gagarawa",
    "Garki",
    "Gumel",
    "Guri",
    "Gwaram",
    "Gwiwa",
    "Hadejia",
    "Jahun",
    "Kafin Hausa",
    "Kazaure",
    "Kiri Kasama",
    "Kiyawa",
    "Kaugama",
    "Maigatari",
    "Malam Madori",
    "Miga",
    "Sule Tankarkar",
    "Roni",
    "Ringim",
    "Yankwashi",
    "Taura",
  ];
  const [verifying, setVerfiying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [psn, setPsn] = useState({
    psn: "",
  });
  
  const [input, setInput] = useState({
    fullname: "",
    psn: "",
    ipps_id: "",
    gradeLevel: "",
    phone: "",
    email: "",
    nin: "",
    bvn: "",
    bank: "",
  gender:"",
  appointmentType:"",
  cropType:"",
    accountNo: "",
    mda: "",
    lga: "",
  community:"",
    farmLoc: "",
    farmLga: "",
    farmWard: "",
    cordinate: "",
    consent: "no",
  });
  const inputHandler = (e) => {
    const name = e.target.name;
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);

    setInput((prevInput) => ({
      ...prevInput,
      [name]: capitalizedValue,
    }));
  };
  const handleRadioChange = (selectedRole) => {
    setInput((prevInput) => ({
      ...prevInput,
      consent: selectedRole,
    }));
  };

  const getStaff = () => {
    setVerfiying(true);
    applyService
      .getStaffByPsn(psn.psn)
      .then((result) => {
        if (result && result.data.success) {
          const info = result.data.data;
          setInput(info);
          setTab(2);
        } else {
          setError("Employee data not found...");
          toast.error(result?.data?.message);
        }
      })
      .catch((error) => {
        console.error("Error response:", error.response); // Add logging for debugging
        if (error.response && error.response.status === 404) {
          setError("Employee data not found...");
          toast.error(error.response?.data?.message ?? "Employee not found");
        } else {
          toast.error(error.response?.data?.message ?? error?.message);
        }
      })
      .finally(() => {
        setVerfiying(false);
      });
  };
  // const navigate = useNavigate();

  const submit = () => {
    setLoading(true);
    if (input.phone === "") {
      toast.error("Phone number is required..!");
      setLoading(false);  // Stop loading if phone number is not provided
      return;  // Prevent form submission
    }
  
    applyService
      .apply(input)
      .then((result) => {
        if (result && result.data.success) {
          toast.success(result.data.message);
          setTimeout(() => {
            // navigate("/");
            setTab(5)
          }, 5000);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message ?? error.response.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const handlePrint = () => {
    const inputed = document.getElementById('capture'); // Replace 'capture' with your element ID
  
    if (!inputed) {
      console.error('Element with ID "capture" not found in the DOM.');
      return;
    }
  
    // Check if we are in tab 5 before proceeding
    if (tab !== 5) {
      console.error('Printing is allowed only from tab 5.');
      return;
    }
  
    html2canvas(inputed, { scrollY: -window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const imgWidth = 595.28;
      const imgHeight = canvas.height * imgWidth / canvas.width;
  
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(`${input.fullname}-application slip`);
    }).catch((error) => {
      console.error('Error capturing element:', error);
    });
  };
  

  
  
  
  return (
    <div id="capture" className="w-full h-full py-6  md:px-4">
      <div className="flex flex-col space-y-3">
        {tab !== 5 ?
        <div className="flex items-center justify-center">
          <p className="text-2xl font-bold text-dark">Apply Loan</p>
        </div> : null}
        {tab === 1 ? (
          <>
            <div className="flex flex-col space-y-2">
              <p>Enter your PSN Number to verify your Identity</p>
              {error ? (
                <>
                  <p className="text-red-500 text-sm">{error}</p>
                </>
              ) : null}
              <div className="flex flex-col md:flex-row space-x-4">
                <div>
                  <Input
                    type="text"
                    name="psn"
                    placeholder="PSN No"
                    onChange={(e) => {
                      setPsn({ ...psn, psn: e.target.value });
                    }}
                    value={psn.psn}
                  />
                </div>
                <div className="mt-2">
                  <SubmitButton
                    disabled={psn.psn === ""}
                    onClick={!verifying ? getStaff : null}
                  >
                    {verifying ? (
                      <ClipLoader color="#fff" size={30} data-testid="loader" />
                    ) : (
                      "Verify"
                    )}
                  </SubmitButton>
                </div>
              </div>
            </div>
          </>
        ) : tab === 2 ? (
          <>
            <div className="flex flex-col space-y-2">
              <p>Enter your Personal details</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <Input
                    type="text"
                    name="fullname"
                    placeholder="Fullname"
                    label="Fullname"
                    disabled
                    required
                    onChange={(e) => {
                      setInput({ ...input, fullname: e.target.value });
                    }}
                    value={input?.fullname ?? ""}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="psn"
                    required
                    label="PSN No"
                    disabled
                    placeholder="PSN No"
                    onChange={(e) => {
                      setInput({ ...input, psn: e.target.value });
                    }}
                    value={input?.psn ?? ""}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="ipps_id"
                    required
                    label="IPPS ID"
                    disabled
                    placeholder="IPPS ID"
                    onChange={(e) => {
                      setInput({ ...input, ipps_id: e.target.value });
                    }}
                    value={input?.ipps_id ?? ""}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="phone"
                    required
                    label="Phone No"
                    placeholder="Phone No"
                    onChange={inputHandler}
                    value={input?.phone ?? ""}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="email"
                    required
                    label="Email Address"
                    placeholder="Email Address"
                    onChange={inputHandler}
                    value={input?.email}
                  />
                </div>

                <div>
                  <SelectField
                    name="gender"
                    required
                    label="Gender"
                    onChange={(e) => {
                      setInput({ ...input, gender: e.target.value });
                    }}
                    value={input?.gender}
                  >
                    <option value="">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </SelectField>
                </div>
                <div>
                  <Input
                    type="text"
                    name="bvn"
                    required
                    label="BVN No"
                    disabled
                    placeholder="BVN No"
                    onChange={inputHandler}
                    value={input?.bvn ?? ""}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="bank"
                    required
                    label="SALARY BANK"
                    disabled
                    placeholder="SALARY BANK"
                    onChange={inputHandler}
                    value={input?.bank ?? ""}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="accountNo"
                    required
                    label="Account Number"
                    placeholder="Account Number"
                    onChange={inputHandler}
                    value={input?.accountNo ?? ""}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    label="MDAs"
                    disabled
                    placeholder="MDAs"
                    name="mda"
                    required
                    onChange={(e) => {
                      setInput({ ...input, mda: e.target.value });
                    }}
                    value={input?.mda ?? ""}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="gradeLevel"
                    required
                    label="Grade Level"
                    disabled
                    placeholder="Grade Level"
                    onChange={inputHandler}
                    value={input?.gradeLevel ?? ""}
                  />
                </div>

                <div>
                  <SelectField
                    name="appointmentType"
                    required
                    label="Appointment Type"
                    onChange={(e) => {
                      setInput({ ...input, appointmentType: e.target.value });
                    }}
                    value={input?.appointmentType}
                  >
                    <option value="">Appointment Type</option>
                    <option value="Permamnent">Permamnent</option>
                    <option value="Contract">Contract</option>
                  </SelectField>
                </div>
                <div>
                  <Input
                    type="text"
                    name="nin"
                    required
                    label="Nin No"
                    placeholder="NIN No"
                    onChange={inputHandler}
                    value={input?.nin}
                  />
                </div>

                <div>
                  <SelectField
                    name="lga"
                    required
                    label="LGA of Origin"
                    onChange={(e) => {
                      setInput({ ...input, lga: e.target.value });
                    }}
                    value={input?.lga}
                  >
                    <option value="">LGA</option>
                    {lgas.map((lga, index) => (
                      <option key={index} value={lga}>
                        {lga}
                      </option>
                    ))}
                  </SelectField>
                </div>

               
                <div className="">
                  <SubmitButton onClick={() => changeTab(1)}>Back</SubmitButton>
                </div>
                <div className="">
                  <SubmitButton onClick={() => changeTab(3)}>Next</SubmitButton>
                </div>
              </div>
            </div>
          </>
        ) : tab === 3 ? (
          <>
            <div className="flex flex-col space-y-2">
              <p>Enter your Farm details</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <SelectField
                    name="cropType"
                    required
                    label="Crop Type"
                    onChange={(e) => {
                      setInput({ ...input, cropType: e.target.value });
                    }}
                    value={input?.cropType}
                  >
                    <option value="">Crop Type</option>
                    <option
                      value="Rice">
                      Rice
                    </option>
                    <option
                      value="Millet">
                      Millet
                    </option>
                    <option
                      value="Sorghum">
                      Sorghum
                    </option>
                    <option
                      value="Sesame">
                      Sesame
                    </option>
                  </SelectField>
                </div>
                <div>
                  <Input
                    type="text"
                    name="farmLoc"
                    required
                    label="Farm Location"
                    placeholder="Farm Location"
                    onChange={inputHandler}
                    value={input?.farmLoc}
                  />
                </div>

                <div>
                  <SelectField
                    name="farmLga"
                    label="Farm LGA"
                    required
                    onChange={(e) => {
                      setInput({ ...input, farmLga: e.target.value });
                    }}
                    value={input?.farmLga}
                  >
                    <option value="">LGA</option>
                    {lgaData.map((lg, index) => (
                      <option key={index} value={lg.localGovernment}>
                        {lg.localGovernment}
                      </option>
                    ))}
                  </SelectField>
                </div>
                <div>
                  <SelectField
                    name="farmWard"
                    label="Farm Ward"
                    required
                    onChange={(e) => {
                      setInput({ ...input, farmWard: e.target.value });
                    }}
                    value={input.farmWard}
                  >
                    <option value="">Farm Ward</option>
                    {lgaData
                      .find((lg) => lg.localGovernment === input.farmLga)
                      ?.wards.map((ward, index) => (
                        <option key={index} value={ward}>
                          {ward}
                        </option>
                      ))}
                  </SelectField>
                </div>
                <div>
                  <Input
                    type="text"
                    name="community"
                    required
                    label="Community"
                    placeholder="Community"
                    onChange={inputHandler}
                    value={input?.community}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="cordinate"
                    required
                    label="Cordinate"
                    placeholder="Cordinate"
                    onChange={inputHandler}
                    value={input?.cordinate}
                  />
                </div>
               
                <div className="">
                  <SubmitButton onClick={() => changeTab(2)}>Back</SubmitButton>
                </div>
                <div className="">
                  <SubmitButton onClick={() => changeTab(4)}>Next</SubmitButton>
                </div>
              </div>
            </div>
          </>
        ) :tab ===4 ? (
          <>
            <div className="flex flex-col space-y-2">
              <div className="flex flex-row justify-center items-center">
                {" "}
                <p className="text-center">Sign Agreement</p>
              </div>

              <div className="flex flex-col space-y-3 py-3">
                <p className="text-xl font-semibold text-dark uppercase">
                  AGREEMENT FOR AGRICULTURAL INPUTS AND CASH LOAN
                </p>
                <p>
                  This Agreement ("Agreement") is made on this 24th day of May,
                  2024. BETWEEN JIGAWA STATE GOVERNMENT, (hereinafter referred
                  to as "the Employer,") which expression shall where the
                  context so admits include its successors-in-title and assigns)
                  of the ONE PART.
                </p>
                <p>
                  AND{" "}
                  <span className="font-semibold text-dark">
                    {" "}
                    {input.fullname}
                  </span>
                </p>
                <p>
                  (hereinafter referred to as "the Beneficiary.") which
                  expression shall where the context so admits include its
                  successors-in-title and assigns) on the OTHER PART.
                </p>
                <p>WHEREAS,</p>
                <p>
                  The Employer recognizes the importance of supporting its
                  employees by providing agricultural inputs and cash loans at a
                  subsidized rate to enhance their agricultural endeavors and
                  financial well-being.
                </p>
                <p>AND WHEREAS,</p>
                <p>
                  The Employee acknowledges the benefits of receiving
                  agricultural inputs and cash loans at a subsidized rate and
                  agrees to abide by the terms and conditions set forth herein.
                </p>
                <p>
                  NOW, THEREFORE, in consideration of the mutual promises and
                  covenants contained herein, the parties agree as follows:
                </p>
                <p>ARTICLE 1: LOAN PROVISION:</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The Employer shall provide agricultural inputs, including
                    but not limited to seeds, fertilizers, and farming
                    equipment, to the Beneficiary at a subsidized rate
                  </li>
                  <li>
                  The Employer shall also provide cash loans to the Beneficiary amounting to…………………………………………………………………………………………………………………..at a subsidized interest rate, subject to the terms and conditions outlined in this Agreement.
                  </li>
                </ul>

                <p>ARTICLE 2: PURPOSE OF THE LOAN</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The Beneficiary agrees to utilize the agricultural inputs
                    provided by the Employer solely for agricultural purposes
                    and not for any other personal or commercial activities.
                  </li>
                  <li>
                    The cash loan received by the Employee shall be utilized for
                    purposes approved by the Employer, including but not limited
                    to agricultural investments.
                  </li>
                </ul>

                <p>ARTICLE 3: DISBURSEMENT OF THE LOAN</p>
                <p>The loan will be disbursed in two parts:</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    Percentage for agricultural inputs and percentage as cash.
                  </li>
                  <li>
                    The beneficiary shall receive the agricultural inputs
                    directly from approved suppliers and the cash portion of the
                    loan directly into their designated bank account.
                  </li>
                </ul>

                <p>ARTICLE 4: LOAN REPAYMENT</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The beneficiary agrees to repay the loan amount
                    in……………………………..monthly instalments.
                  </li>
                  <li>
                    The repayment of the loan shall commence on the…………………..and
                    shall continue until the full repayment of the loan amount
                    and any applicable interest.
                  </li>
                  <li>
                    The repayment amount shall be deducted directly from the
                    Beneficiary's salary at the source by the Employer until the
                    loan is fully repaid.
                  </li>
                </ul>

                <p>ARTICLE 5: OBLIGATION OF THE EMPLOYEE</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The beneficiary agrees to use the loan exclusively for the
                    intended purpose of purchasing agricultural inputs and
                    related agricultural expenses.
                  </li>
                  <li>
                    The beneficiary shall not transfer or assign any rights or
                    obligations under this Agreement without the prior written
                    consent of the employer.
                  </li>
                </ul>

                {/* <p>ARTICLE 6: INTEREST RATE</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The interest rate applicable to the cash loan provided by
                    the Employer shall be at a subsidized rate, as determined by
                    the Employer.
                  </li>
                </ul> */}

                <p>ARTICLE 6: DEFAULT</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    In the event of default in the repayment of the loan by the
                    Beneficiary, the Employer reserves the right to take
                    necessary actions, including but not limited to salary
                    deductions, legal proceedings, and recovery of the
                    outstanding amount.
                  </li>
                </ul>

                <p>ARTICLE 7: TERMINATION</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    This agreement shall terminate upon the full payment of the
                    loan amount.
                  </li>
                  <li>
                    The employer reserves the rights to terminate this agreement
                    with immediate effect if the employee is found to have
                    misused the loan or provided false information.
                  </li>
                  <li>
                    In the event of termination of employment for any reason,
                    the outstanding loan amount, if any, shall become
                    immediately due and payable by the Beneficiary to the
                    Employer.
                  </li>
                </ul>

                <p>ARTICLE 8: GOVERNING LAW </p>
                <p>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of Jigawa State.
                </p>
                <p>ARTICLE 9: ENTIRE AGREEMENT:</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The beneficiary agrees to repay the loan amount
                    in……………………………..monthly instalments.
                  </li>
                  <li>
                    The repayment of the loan shall commence on the…………………..and
                    shall continue until the full repayment of the loan amount
                    and any applicable interest.
                  </li>
                  <li>
                    The repayment amount shall be deducted directly from the
                    Beneficiary's salary at the source by the Employer until the
                    loan is fully repaid.
                  </li>
                </ul>

                <p>ARTICLE 10. MISCELLANEOUS</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    This Agreement constitutes the entire understanding and
                    agreement between the parties hereto with respect to the
                    subject matter hereof and supersedes all prior agreements,
                    understandings, negotiations, and discussions, whether oral
                    or written, between the parties hereto relating to the
                    subject matter hereof.
                  </li>
                  <li>
                    This Agreement may be amended or modified only by a written
                    instrument executed by both parties hereto.
                  </li>
                  <li>
                    The repayment amount shall be deducted directly from the
                    Beneficiary's salary at the source by the Employer until the
                    loan is fully repaid.
                  </li>
                </ul>

                <p>ARTICLE 11: FORCE MAJEURE</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    In this Agreement, “Force Majeure Event” means, in relation
                    to either party, any event if occasioned by or resulting
                    from an act of God or public enemy, fire, explosion,
                    earthquake, perils of the sea, flood, storm or other adverse
                    weather conditions, war declared or undeclared, civil war,
                    revolution, civil commotion or other civil strife, riot,
                    strikes, blockade, embargo, sanctions, epidemics, pandemics,
                    act of any government or other authority, compliance with
                    government orders, demands or regulations, or any
                    circumstances of like or different nature beyond the
                    reasonable control of the Party, save to the extent that the
                    same could reasonably have been avoided by the party.
                  </li>
                  <li>
                    Neither party shall be in default under this Agreement if
                    and to the extent that any delay in such Party’s performance
                    of one or more of its obligations hereunder is caused by a
                    Force Majeure Event, and such Party’s performance of such
                    obligation or obligations shall be excused and extended for
                    and during the period of any such delay.
                  </li>
                  <li>
                    The party claiming relief under this Clause shall promptly
                    notify the other in writing of the existence of the Force
                    Majeure Event relied on, the anticipated length of delay,
                    the cause of the delay and a timetable by which any remedial
                    measures will be implemented. If the Force Majeure Event is
                    not remedied within 60 days, and Parties fail to reach an
                    agreement then either party may terminate this Agreement by
                    giving Twenty (20) working days written notice to the other
                    Party.
                  </li>
                </ul>

                <div className="flex">
                  <RadioField
                    checked={input.consent === "yes"}
                    onChange={() => handleRadioChange("yes")}
                    name="consent"
                    label="Agree with agreement"
                    id="consent"
                    value={input.consent}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="">
                    <SubmitButton onClick={() => changeTab(3)}>
                      Back
                    </SubmitButton>
                  </div>
                  <div className="">
                    <SubmitButton onClick={!loading ? submit : null}>
                      {loading ? (
                        <ClipLoader
                          color="#fff"
                          size={30}
                          data-testid="loader"
                        />
                      ) : (
                        "Submit"
                      )}
                    </SubmitButton>
                  </div>
                </div>
              </div>
            </div>
          </>
        ):(
          <>
          <div >
          <div id="capture" className="p-6 flex flex-col space-y-4">
  <div  className="flex flex-col space-y-3 text-center">
  <p className="text-2xl font-bold text-dark">
          Technical Committee on Jigawa Worker Agricultural Support Programme
        </p>
    <p className="text-xl text-center text-dark font-medium">
       Application Slip
    </p>
  </div>

  <div className="p-3 bg-white my-2">
  <table className="table-auto w-full border-collapse border border-gray-200">
  <tbody>
    <tr className="border-b border-gray-200">
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant Name:</th>
      <td className="text-md py-2 px-4">{input.fullname}</td>
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant PSN NO:</th>
      <td className="text-md py-2 px-4">{input.psn}</td>
    </tr>
    <tr className="border-b border-gray-200">
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant Phone:</th>
      <td className="text-md py-2 px-4">{input.phone}</td>
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant Email:</th>
      <td className="text-md py-2 px-4">{input.email}</td>
    </tr>
    <tr className="border-b border-gray-200">
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant Gender:</th>
      <td className="text-md py-2 px-4">{input.gender}</td>
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant BVN:</th>
      <td className="text-md py-2 px-4">{input.bvn}</td>
    </tr>
    <tr className="border-b border-gray-200">
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant NIN:</th>
      <td className="text-md py-2 px-4">{input.nin}</td>
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant Bank:</th>
      <td className="text-md py-2 px-4">{input.bank}</td>
    </tr>
    <tr className="border-b border-gray-200">
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant Account Number:</th>
      <td className="text-md py-2 px-4">{input.accountNo}</td>
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant Grade Level:</th>
      <td className="text-md py-2 px-4">{input.gradeLevel}</td>
    </tr>
    <tr className="border-b border-gray-200">
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant MDA:</th>
      <td className="text-md py-2 px-4">{input.mda}</td>
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Appointment Type:</th>
      <td className="text-md py-2 px-4">{input.appointmentType}</td>
    </tr>
    <tr className="border-b border-gray-200">
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Applicant LGA:</th>
      <td className="text-md py-2 px-4">{input.lga}</td>
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Farm Location:</th>
      <td className="text-md py-2 px-4">{input.farmLoc}</td>
    </tr>
    <tr className="border-b border-gray-200">
    <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Community:</th>
    <td className="text-md py-2 px-4">{input.community}</td>
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Crop Type:</th>
      <td className="text-md py-2 px-4">{input.cropType}</td>
    </tr>
    <tr className="border-b border-gray-200">
     
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Farm Ward:</th>
      <td className="text-md py-2 px-4">{input.farmWard}</td>
      <th className="border-r border-gray-200 text-dark text-md font-semibold py-2 px-4">Farm LGA:</th>
      <td className="text-md py-2 px-4">{input.farmLga}</td>
    </tr>
  </tbody>
</table>

  </div>
  <p>you have agree to the terms and conditions of the loan.</p>
  
  {/* <div className="flex flex-col space-y-2 text-dark">

              <div className="flex flex-col space-y-3 py-3">
                <p className="text-xl font-semibold text-dark uppercase">
                  AGREEMENT FOR AGRICULTURAL INPUTS AND CASH LOAN
                </p>
                <p>
                  This Agreement ("Agreement") is made on this 24th day of May,
                  2024. BETWEEN JIGAWA STATE GOVERNMENT, (hereinafter referred
                  to as "the Employer,") which expression shall where the
                  context so admits include its successors-in-title and assigns)
                  of the ONE PART.
                </p>
                <p>
                  AND{" "}
                  <span className="font-semibold text-dark">
                    {" "}
                    {input.fullname}
                  </span>
                </p>
                <p>
                  (hereinafter referred to as "the Beneficiary.") which
                  expression shall where the context so admits include its
                  successors-in-title and assigns) on the OTHER PART.
                </p>
                <p>WHEREAS,</p>
                <p>
                  The Employer recognizes the importance of supporting its
                  employees by providing agricultural inputs and cash loans at a
                  subsidized rate to enhance their agricultural endeavors and
                  financial well-being.
                </p>
                <p>AND WHEREAS,</p>
                <p>
                  The Employee acknowledges the benefits of receiving
                  agricultural inputs and cash loans at a subsidized rate and
                  agrees to abide by the terms and conditions set forth herein.
                </p>
                <p>
                  NOW, THEREFORE, in consideration of the mutual promises and
                  covenants contained herein, the parties agree as follows:
                </p>
                <p>ARTICLE 1: LOAN PROVISION:</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The Employer shall provide agricultural inputs, including
                    but not limited to seeds, fertilizers, and farming
                    equipment, to the Beneficiary at a subsidized rate
                  </li>
                  <li>
                    The Employer shall also provide cash loans to the
                    Beneficiary amounting to at a subsidized interest rate,
                    subject to the terms and conditions outlined in this
                    Agreement.
                  </li>
                </ul>

                <p>ARTICLE 2: PURPOSE OF THE LOAN</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The Beneficiary agrees to utilize the agricultural inputs
                    provided by the Employer solely for agricultural purposes
                    and not for any other personal or commercial activities.
                  </li>
                  <li>
                    The cash loan received by the Employee shall be utilized for
                    purposes approved by the Employer, including but not limited
                    to agricultural investments.
                  </li>
                </ul>

                <p>ARTICLE 3: DISBURSEMENT OF THE LOAN</p>
                <p>The loan will be disbursed in two parts:</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    Percentage for agricultural inputs and percentage as cash.
                  </li>
                  <li>
                    The beneficiary shall receive the agricultural inputs
                    directly from approved suppliers and the cash portion of the
                    loan directly into their designated bank account.
                  </li>
                </ul>

                <p>ARTICLE 4: LOAN REPAYMENT</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The beneficiary agrees to repay the loan amount
                    in……………………………..monthly instalments.
                  </li>
                  <li>
                    The repayment of the loan shall commence on the…………………..and
                    shall continue until the full repayment of the loan amount
                    and any applicable interest.
                  </li>
                  <li>
                    The repayment amount shall be deducted directly from the
                    Beneficiary's salary at the source by the Employer until the
                    loan is fully repaid.
                  </li>
                </ul>

                <p>ARTICLE 5: OBLIGATION OF THE EMPLOYEE</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The beneficiary agrees to use the loan exclusively for the
                    intended purpose of purchasing agricultural inputs and
                    related agricultural expenses.
                  </li>
                  <li>
                    The beneficiary shall not transfer or assign any rights or
                    obligations under this Agreement without the prior written
                    consent of the employer.
                  </li>
                </ul>

                <p>ARTICLE 6: INTEREST RATE</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The interest rate applicable to the cash loan provided by
                    the Employer shall be at a subsidized rate, as determined by
                    the Employer.
                  </li>
                </ul>

                <p>ARTICLE 7: DEFAULT</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    In the event of default in the repayment of the loan by the
                    Beneficiary, the Employer reserves the right to take
                    necessary actions, including but not limited to salary
                    deductions, legal proceedings, and recovery of the
                    outstanding amount.
                  </li>
                </ul>

                <p>ARTICLE 8: TERMINATION</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    This agreement shall terminate upon the full payment of the
                    loan amount.
                  </li>
                  <li>
                    The employer reserves the rights to terminate this agreement
                    with immediate effect if the employee is found to have
                    misused the loan or provided false information.
                  </li>
                  <li>
                    In the event of termination of employment for any reason,
                    the outstanding loan amount, if any, shall become
                    immediately due and payable by the Beneficiary to the
                    Employer.
                  </li>
                </ul>

                <p>ARTICLE 9: GOVERNING LAW </p>
                <p>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of Jigawa State.
                </p>
                <p>ARTICLE 10: ENTIRE AGREEMENT:</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    The beneficiary agrees to repay the loan amount
                    in……………………………..monthly instalments.
                  </li>
                  <li>
                    The repayment of the loan shall commence on the…………………..and
                    shall continue until the full repayment of the loan amount
                    and any applicable interest.
                  </li>
                  <li>
                    The repayment amount shall be deducted directly from the
                    Beneficiary's salary at the source by the Employer until the
                    loan is fully repaid.
                  </li>
                </ul>

                <p>ARTICLE 11. MISCELLANEOUS</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    This Agreement constitutes the entire understanding and
                    agreement between the parties hereto with respect to the
                    subject matter hereof and supersedes all prior agreements,
                    understandings, negotiations, and discussions, whether oral
                    or written, between the parties hereto relating to the
                    subject matter hereof.
                  </li>
                  <li>
                    This Agreement may be amended or modified only by a written
                    instrument executed by both parties hereto.
                  </li>
                  <li>
                    The repayment amount shall be deducted directly from the
                    Beneficiary's salary at the source by the Employer until the
                    loan is fully repaid.
                  </li>
                </ul>

                <p>ARTICLE 12: FORCE MAJEURE</p>
                <ul className="flex flex-col space-y-3">
                  <li>
                    In this Agreement, “Force Majeure Event” means, in relation
                    to either party, any event if occasioned by or resulting
                    from an act of God or public enemy, fire, explosion,
                    earthquake, perils of the sea, flood, storm or other adverse
                    weather conditions, war declared or undeclared, civil war,
                    revolution, civil commotion or other civil strife, riot,
                    strikes, blockade, embargo, sanctions, epidemics, pandemics,
                    act of any government or other authority, compliance with
                    government orders, demands or regulations, or any
                    circumstances of like or different nature beyond the
                    reasonable control of the Party, save to the extent that the
                    same could reasonably have been avoided by the party.
                  </li>
                  <li>
                    Neither party shall be in default under this Agreement if
                    and to the extent that any delay in such Party’s performance
                    of one or more of its obligations hereunder is caused by a
                    Force Majeure Event, and such Party’s performance of such
                    obligation or obligations shall be excused and extended for
                    and during the period of any such delay.
                  </li>
                  <li>
                    The party claiming relief under this Clause shall promptly
                    notify the other in writing of the existence of the Force
                    Majeure Event relied on, the anticipated length of delay,
                    the cause of the delay and a timetable by which any remedial
                    measures will be implemented. If the Force Majeure Event is
                    not remedied within 60 days, and Parties fail to reach an
                    agreement then either party may terminate this Agreement by
                    giving Twenty (20) working days written notice to the other
                    Party.
                  </li>
                </ul> 
                <div className="flex">
                  <RadioField
                    checked={true}
                    // onChange={() => handleRadioChange("yes")}
                    name="consent"
                    disbaled
                    label="Agree with agreement"
                    id="consent"
                    value={input.consent}
                  />
                </div>
               
                 
                  <div className="">
                    <SubmitButton onClick={!loading ? handlePrint : null}>
                      {loading ? (
                        <ClipLoader
                          color="#fff"
                          size={30}
                          data-testid="loader"
                        />
                      ) : (
                        "print Slip"
                      )}
                    </SubmitButton>
                 
                </div>
              </div>
            </div> */}
</div>
  <div className="w-40 flex justify-end">
                    <SubmitButton onClick={!loading ? handlePrint : null}>
                      {loading ? (
                        <ClipLoader
                          color="#fff"
                          size={30}
                          data-testid="loader"
                        />
                      ) : (
                        "print Slip"
                      )}
                    </SubmitButton>
                 
                </div>
</div>
          </>
        )}
      </div>
    </div>
  );
};

export default Apply;
