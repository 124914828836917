import React from 'react';
import { Link } from 'react-router-dom';

function ApprovalCheckSelection() {
    return (
        <div className="approval-check-section my-8 p-8 bg-yellow-100 border-4 border-green-500 shadow-lg">
            <h2 className="text-3xl font-bold text-green-600 mb-6">Successful Applicant Selection </h2>
            <p className="text-lg mb-6">If you've applied and want to see if you've been selected, click the link below:</p>
            <Link to="/verify-selection" className="mt-4 inline-block bg-green-600 text-white py-3 px-6 text-xl rounded-lg hover:bg-green-700 transition-colors">
                Check Selection
            </Link>
        </div>
    );
}

export default ApprovalCheckSelection;
