import { useEffect, useRef } from 'react'

const SessionTimeout = ({ timeoutInMinutes, onTimeout }) => {
    const timeoutRef = useRef(null)

    useEffect(() => {
        const resetTimeout = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }

            timeoutRef.current = setTimeout(
                onTimeout,
                timeoutInMinutes * 60 * 1000
            )
        }

        const clearAndResetTimeout = () => {
            resetTimeout()
        }

        const handleInteraction = () => {
            clearAndResetTimeout()
        }

        clearAndResetTimeout()

        // Add event listeners for interactions
        document.addEventListener('mousemove', handleInteraction)
        document.addEventListener('keydown', handleInteraction)
        document.addEventListener('wheel', handleInteraction)
        document.addEventListener('touchstart', handleInteraction)

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }

            // Remove event listeners when component unmounts
            document.removeEventListener('mousemove', handleInteraction)
            document.removeEventListener('keydown', handleInteraction)
            document.removeEventListener('wheel', handleInteraction)
            document.removeEventListener('touchstart', handleInteraction)
        }
    }, [timeoutInMinutes, onTimeout])

    return null
}

export default SessionTimeout
