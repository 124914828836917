/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaTimesCircle } from 'react-icons/fa';
import ReactToPrint from 'react-to-print';
import Certificate from '../../../components/PlaCertificate';
import { toast } from "react-toastify";

import { applyService } from "../../../services/apply/apply.service";


function ViewPlaCertificate() {
    const certificateRef = useRef();
    const [error, setError] = useState("");
    const [applicant, setApplicant] = useState({
        id: "null",
        name: "null",
        pla: "null",
        designation: "null",
        portfolio: "null",
        cropType: "null",
        phoneNumber: "null",
        farmLga: "null",
        farmLocation: "null",
        issueDate: "null",
    });
    // Inside your component
    const location = useLocation();
    const { plaValue } = location.state || {}; // Ensure to handle the case where state or psn might be undefined


    const getStaff = () => {
        applyService
            .getPlaStaffByPla(plaValue)
            .then((result) => {
                if (result && result.data.success) {
                    setApplicant({
                        id: result.data.data.id,
                        name: result.data.data.fullname,
                        plaNumber: result.data.data.plaNumber,
                        designation: result.data.data.designation,
                        portfolio: result.data.data.portfolio,
                        cropType: result.data.data.cropType,
                        farmSize: result.data.data.farmSize,
                        phoneNumber: result.data.data.phoneNumber,
                        farmLga: result.data.data.farmLga,
                        farmLocation: result.data.data.farmLga,
                        issueDate: result.data.data.issueDate,
                    })

                } else {
                    setError("Employee Not Approved...");
                    toast.error("Employee Not Approved...");
                }
            })
            .catch((error) => {
                console.error("Error response:", error.response); // Add logging for debugging
                if (error.response && error.response.status === 404) {
                    setError("Employee Not Approved...");
                    toast.error(error.response?.data?.message ?? "Employee Not Approved...");
                } else {
                    toast.error(error.response?.data?.message ?? error?.message);
                }
            })
    };

    useEffect(() => {
        getStaff();
    }, [plaValue])

    return (
        error ? (
            <div className='h-fit min-h-[50vh]'>
                <div className="error-message flex items-center text-red-600">
                    <FaTimesCircle size={24} className="mr-2" />
                    <span>Employee with PLA number not approved</span>
                </div>
            </div>
        ) : (
            <div className="flex flex-col items-center justify-center min-h-screen h-fit p-4 relative">

                <div className="mb-8 p-4 border relative border-gray-300 rounded shadow overflow-hidden h-full w-full max-w-4xl">
                    <Certificate ref={certificateRef} applicant={applicant} />
                </div>
                <ReactToPrint
                    trigger={() => <button className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors duration-200 ease-in-out">Print Certificate</button>}
                    content={() => certificateRef.current}
                />
            </div>

        )
    );
}

export default ViewPlaCertificate;
