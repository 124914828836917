import React, { forwardRef } from 'react';
import QRCode from 'qrcode.react';
import { DoubleRiceTable, TripleRiceTable } from './CropTable';

const Certificate = forwardRef(({ applicant }, ref) => {

    const renderTable = () => {
        console.log(applicant?.farmSize);
        switch (applicant?.farmSize) {
            case '2':
                return <DoubleRiceTable />;
            case '3':
                return <TripleRiceTable />;
            default:
                return <p>Invalid crop type</p>;
        }
    };


    const qrCodeValue = `
    Name: ${applicant.fullname}
    PLA: ${applicant.plaNumber}
    Designation : ${applicant.designation}
   Portfolio : ${applicant.portfolio}
    Crop Type: ${applicant.cropType}
    Phone Number: ${applicant.phoneNumber}
    LGA of Origin: ${applicant.lgaOrigin}
    Farm LGA: ${applicant.farmLga}
    Farm Location: ${applicant.farmLga}
    Farm Size: ${applicant.farmSize} Hectares
    Issued on: ${applicant.issueDate}
    `;
    return (
        <div
            ref={ref}
            className="flex flex-col w-full h-fit relative items-center justify-center p-12"
        >
            <div className='certificate relative  bg-cover bg-center flex flex-col items-center justify-center'>
                <div className="text-center bg-white bg-opacity-80 p-4 rounded-lg shadow-lg relative text-black">
                    <h1 className="text-2xl font-bold mb-4">Technical Committee on Workers Agricultural Support Programme</h1>
                    <p className="text-lg mb-2">This is to certify that</p>
                    <p className="text-2xl font-semibold mb-4">{applicant.name}</p>
                    <p className='text-xl font-bold mb-2'> {applicant.designation} {applicant.portfolio}</p>
                    <div className="text-left grid grid-cols-2 place-content-center">
                        <p>PLA Number: {applicant.plaNumber}</p>

                        <p>Crop Type: {applicant.cropType}</p>
                        <p>Phone Number: {applicant.phoneNumber}</p>
                        <p>Farm LGA: {applicant.farmLga}</p>
                        <p>Farm Location: {applicant.farmLga}</p>

                    </div>
                    <h4 className='font-extrabold p-2'>Redemption Center: {applicant.farmLga} </h4>
                    <div className="mt-4">
                        <h5 className='capitalize'>Input and Labour Cost Analysis for {applicant.cropType} Farming for {applicant.size} Hectare</h5>
                        {renderTable()}
                    </div>
                    <p className="mt-4 ml-2 text-base">For Enquiries Contact 08064356660</p>
                    <div className="mt-8">
                        <QRCode value={qrCodeValue} />
                    </div>
                </div>
            </div>

        </div>
    );
});

export default Certificate;
