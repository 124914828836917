import React from "react";

const tableStyles =
    "table-auto w-full text-left border-collapse border border-gray-400 my-2";
const headerCellStyles = "border border-gray-400 px-2 py-2 bg-gray-100";
const cellStyles = "border border-gray-400 px-3 py-2";

const MilletTable = () => (
    <table className={tableStyles}>
        <thead>
            <tr>
                <th className={headerCellStyles}>ITEMS</th>
                <th className={headerCellStyles}>INPUTS QTY</th>
                <th className={headerCellStyles}>UNIT COST</th>
                <th className={headerCellStyles}>AMOUNT</th>
                <th className={headerCellStyles}>Cost Of Labour</th>
                <th className={headerCellStyles}>Total Amount Allocated</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className={cellStyles}>NPK</td>
                <td className={cellStyles}>3-BAGS</td>
                <td className={cellStyles}>₦32,000</td>
                <td className={cellStyles}>₦96,000</td>
                <td className={cellStyles} rowSpan={4}>

                    ₦112,500
                </td>
                <td className={cellStyles} rowSpan={4}>
                    ₦250,000 (₦112,500 + ₦137,500) = ₦250,000
                </td>
            </tr>
            <tr>
                <td className={cellStyles}>UREA</td>
                <td className={cellStyles}>1-BAG</td>
                <td className={cellStyles}>₦38,000</td>
                <td className={cellStyles}>₦38,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>INSECTICIDE (IMI FORCE)</td>
                <td className={cellStyles}>1-BOTTLE</td>
                <td className={cellStyles}>₦3,500</td>
                <td className={cellStyles}>₦3,500</td>
            </tr>
            <tr>
                <td className={cellStyles} colSpan={3}>Total</td>
                <td className={cellStyles}>₦137,500</td>
            </tr>
        </tbody>
    </table>
);

const RiceTable = () => (
    <table className={tableStyles}>
        <thead>
            <tr>
                <th className={headerCellStyles}>ITEMS</th>
                <th className={headerCellStyles}>INPUTS QTY</th>
                <th className={headerCellStyles}>UNIT COST</th>
                <th className={headerCellStyles}>AMOUNT</th>
                <th className={headerCellStyles}>Cost Of Labour</th>
                <th className={headerCellStyles}>Total Amount Allocated</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className={cellStyles}>NPK</td>
                <td className={cellStyles}>6-BAGS</td>
                <td className={cellStyles}>₦32,000</td>
                <td className={cellStyles}>₦192,000</td>
                <td className={cellStyles} rowSpan={5}>
                    ₦155,500
                </td>
                <td className={cellStyles} rowSpan={5}>
                    ₦500,000 (₦344,500 + ₦155,500) = <br /> ₦500,000
                </td>
            </tr>
            <tr>
                <td className={cellStyles}>UREA</td>
                <td className={cellStyles}>3-BAGS</td>
                <td className={cellStyles}>₦38,000</td>
                <td className={cellStyles}>₦114,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>PRE-EMERGE (BUTA-FORCE)</td>
                <td className={cellStyles}>4-LITRES</td>
                <td className={cellStyles}>₦7,000</td>
                <td className={cellStyles}>₦28,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>FUNGICIDE (GLORY)</td>
                <td className={cellStyles}>1-SACHET</td>
                <td className={cellStyles}>₦7,000</td>
                <td className={cellStyles}>₦7,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>INSECTICIDE (IMI FORCE)</td>
                <td className={cellStyles}>1-BOTTLE (250ML)</td>
                <td className={cellStyles}>₦3,500</td>
                <td className={cellStyles}>₦3,500</td>
            </tr>
        </tbody>
    </table>
);

const SorghumTable = () => (
    <table className={tableStyles}>
        <thead>
            <tr>
                <th className={headerCellStyles}>ITEMS</th>
                <th className={headerCellStyles}>INPUTS QTY</th>
                <th className={headerCellStyles}>UNIT COST</th>
                <th className={headerCellStyles}>AMOUNT</th>
                <th className={headerCellStyles}>Cost Of Labour</th>
                <th className={headerCellStyles}>Total Amount Allocated</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className={cellStyles}>NPK</td>
                <td className={cellStyles}>3-BAGS</td>
                <td className={cellStyles}>₦32,000</td>
                <td className={cellStyles}>₦96,000</td>
                <td className={cellStyles} rowSpan={4}>

                    ₦112,500
                </td>
                <td className={cellStyles} rowSpan={4}>
                    ₦250,000 (₦112,500 + ₦137,500) = ₦250,000
                </td>
            </tr>
            <tr>
                <td className={cellStyles}>UREA</td>
                <td className={cellStyles}>1-BAG</td>
                <td className={cellStyles}>₦38,000</td>
                <td className={cellStyles}>₦38,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>INSECTICIDE (IMI FORCE)</td>
                <td className={cellStyles}>1-BOTTLE</td>
                <td className={cellStyles}>₦3,500</td>
                <td className={cellStyles}>₦3,500</td>
            </tr>
            <tr>
                <td className={cellStyles} colSpan={3}>Total</td>
                <td className={cellStyles}>₦137,500</td>
            </tr>
        </tbody>
    </table>
);

const SesameTable = () => (
    <table className={tableStyles}>
        <thead>
            <tr>
                <th className={headerCellStyles}>ITEMS</th>
                <th className={headerCellStyles}>INPUTS QTY</th>
                <th className={headerCellStyles}>UNIT COST</th>
                <th className={headerCellStyles}>AMOUNT</th>
                <th className={headerCellStyles}>Cost Of Labour</th>
                <th className={headerCellStyles}>Total Amount Allocated</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className={cellStyles}>NPK</td>
                <td className={cellStyles}>3-BAGS</td>
                <td className={cellStyles}>₦32,000</td>
                <td className={cellStyles}>₦96,000</td>
                <td className={cellStyles} rowSpan={4}>

                    ₦112,500
                </td>
                <td className={cellStyles} rowSpan={4}>
                    ₦250,000 (₦112,500 + ₦137,500) = ₦250,000
                </td>
            </tr>
            <tr>
                <td className={cellStyles}>UREA</td>
                <td className={cellStyles}>1-BAG</td>
                <td className={cellStyles}>₦38,000</td>
                <td className={cellStyles}>₦38,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>INSECTICIDE (IMI FORCE)</td>
                <td className={cellStyles}>1-BOTTLE</td>
                <td className={cellStyles}>₦3,500</td>
                <td className={cellStyles}>₦3,500</td>
            </tr>
            <tr>
                <td className={cellStyles} colSpan={3}>Total</td>
                <td className={cellStyles}>₦137,500</td>
            </tr>
        </tbody>
    </table>
);

const DoubleRiceTable = () => (
    <table className={tableStyles}>
        <thead>
            <tr>
                <th className={headerCellStyles}>ITEMS</th>
                <th className={headerCellStyles}>INPUTS QTY</th>
                <th className={headerCellStyles}>UNIT COST</th>
                <th className={headerCellStyles}>AMOUNT</th>
                <th className={headerCellStyles}>Cost Of Labour</th>
                <th className={headerCellStyles}>Total Amount Allocated</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className={cellStyles}>NPK</td>
                <td className={cellStyles}>12-BAGS</td>
                <td className={cellStyles}>₦32,000</td>
                <td className={cellStyles}>₦384,000</td>
                <td className={cellStyles} rowSpan={5}>
                    ₦311,000
                </td>
                <td className={cellStyles} rowSpan={5}>
                    ₦1,000,000 (₦689,000 + ₦311,000) = <br /> ₦1,000,000
                </td>
            </tr>
            <tr>
                <td className={cellStyles}>UREA</td>
                <td className={cellStyles}>6-BAGS</td>
                <td className={cellStyles}>₦38,000</td>
                <td className={cellStyles}>₦228,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>PRE-EMERGE (BUTA-FORCE)</td>
                <td className={cellStyles}>8-LITRES</td>
                <td className={cellStyles}>₦7,000</td>
                <td className={cellStyles}>₦56,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>FUNGICIDE (GLORY)</td>
                <td className={cellStyles}>2-SACHET</td>
                <td className={cellStyles}>₦7,000</td>
                <td className={cellStyles}>₦14,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>INSECTICIDE (IMI FORCE)</td>
                <td className={cellStyles}>2-BOTTLE (250ML)</td>
                <td className={cellStyles}>₦3,500</td>
                <td className={cellStyles}>₦7,000</td>
            </tr>
            <tr>
                <td className={cellStyles} colSpan={3}>Total</td>
                <td className={cellStyles}>₦689,000</td>
            </tr>
        </tbody>
    </table>
);

const TripleRiceTable = () => (
    <table className={tableStyles}>
        <thead>
            <tr>
                <th className={headerCellStyles}>ITEMS</th>
                <th className={headerCellStyles}>INPUTS QTY</th>
                <th className={headerCellStyles}>UNIT COST</th>
                <th className={headerCellStyles}>AMOUNT</th>
                <th className={headerCellStyles}>Cost Of Labour</th>
                <th className={headerCellStyles}>Total Amount Allocated</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className={cellStyles}>NPK</td>
                <td className={cellStyles}>18-BAGS</td>
                <td className={cellStyles}>₦32,000</td>
                <td className={cellStyles}>₦576,000</td>
                <td className={cellStyles} rowSpan={5}>
                    ₦466,500
                </td>
                <td className={cellStyles} rowSpan={5}>
                    ₦1,500,000 (₦1,033,500 + ₦466,500) = <br /> ₦1,500,000
                </td>
            </tr>
            <tr>
                <td className={cellStyles}>UREA</td>
                <td className={cellStyles}>9-BAGS</td>
                <td className={cellStyles}>₦38,000</td>
                <td className={cellStyles}>₦342,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>PRE-EMERGE (BUTA-FORCE)</td>
                <td className={cellStyles}>12-LITRES</td>
                <td className={cellStyles}>₦7,000</td>
                <td className={cellStyles}>₦84,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>FUNGICIDE (GLORY)</td>
                <td className={cellStyles}>3-SACHET</td>
                <td className={cellStyles}>₦7,000</td>
                <td className={cellStyles}>₦21,000</td>
            </tr>
            <tr>
                <td className={cellStyles}>INSECTICIDE (IMI FORCE)</td>
                <td className={cellStyles}>3-BOTTLE (250ML)</td>
                <td className={cellStyles}>₦3,500</td>
                <td className={cellStyles}>₦10,500</td>
            </tr>
            <tr>
                <td className={cellStyles} colSpan={3}>Total</td>
                <td className={cellStyles}>₦1,033,500</td>
            </tr>
        </tbody>
    </table>
);

export { MilletTable, RiceTable, SorghumTable, SesameTable, DoubleRiceTable, TripleRiceTable };
