/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/controls/Input";
import moment from "moment";
import SubmitButton from "../../components/controls/SubmitButton";
import { applyService } from "../../services/apply/apply.service";
import { FaTimesCircle } from 'react-icons/fa';
import { adminService } from "../../services/admin/admin.service";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const Apply = () => {
    const [error, setError] = useState("");
    const [portalData, setPortalData] = useState({});
    const [verifying, setVerfiying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [psn, setPsn] = useState({
        psn: "",
    });

    const navigate = useNavigate();

    const getAllReports = () => {
        adminService
            .getPortal()
            .then((result) => {
                if (result?.data) {
                    const data = result.data.data;
                    // Convert Unix timestamps to moment objects
                    data.openTime = moment.unix(data.openTime);
                    data.closeTime = moment.unix(data.closeTime);

                    // Calculate remaining time in milliseconds
                    const currentTime = moment();
                    const remaining = data.closeTime.diff(currentTime);

                    setPortalData(data);

                } else {
                    console.error("Data structure is not as expected or empty.");
                    setPortalData({});
                    setPortalData({ open: "no" });
                }
            })
            .catch((error) => {
                console.error("Error fetching portal data:", error);
                setPortalData({ open: "no" });
            })
    };

    useEffect(() => {
        getAllReports();

    }, []);


    const getStaff = () => {
        if (!portalData || (Object.keys(portalData).length === 0 && portalData.constructor === Object)) {
            toast.error("Printing of Certificates is currently disabled");
            return;
        }
        setVerfiying(true);
        applyService
            .getApprovedStaffByPsn(psn.psn)
            .then((result) => {
                if (result && result.data.success) {
                    navigate("/verified-application", { state: { psnValue: psn.psn } })

                } else {
                    setError("Employee Not Approved...");
                    toast.error("Employee Not Approved...");
                }
            })
            .catch((error) => {
                console.error("Error response:", error.response); // Add logging for debugging
                if (error.response && error.response.status === 404) {
                    setError("Employee Not Approved...");
                    toast.error(error.response?.data?.message ?? "Employee Not Approved...");
                } else {
                    toast.error(error.response?.data?.message ?? error?.message);
                }
            })
            .finally(() => {
                setVerfiying(false);
            });
    };
    const getPla = () => {
        if (!portalData || (Object.keys(portalData).length === 0 && portalData.constructor === Object)) {
            toast.error("Printing of Certificates is currently disabled");
            return;
        }
        setVerfiying(true);
        applyService
            .getPlaStaffByPla(psn.psn)
            .then((result) => {
                if (result && result.data.success) {
                    navigate("/verified-application", { state: { plaValue: psn.psn } })

                } else {
                    setError("Employee Not Approved...");
                    toast.error("Employee Not Approved...");
                }
            })
            .catch((error) => {
                console.error("Error response:", error.response); // Add logging for debugging
                if (error.response && error.response.status === 404) {
                    setError("Employee Not Approved...");
                    toast.error(error.response?.data?.message ?? "Employee Not Approved...");
                } else {
                    toast.error(error.response?.data?.message ?? error?.message);
                }
            })
            .finally(() => {
                setVerfiying(false);
            });
    };
    // const navigate = useNavigate();

    // const submit = () => {
    //     setLoading(true);
    //     if (input.phone === "") {
    //         toast.error("Phone number is required..!");
    //         setLoading(false);  // Stop loading if phone number is not provided
    //         return;  // Prevent form submission
    //     }

    //     applyService
    //         .apply(input)
    //         .then((result) => {
    //             if (result && result.data.success) {
    //                 toast.success(result.data.message);
    //                 setTimeout(() => {
    //                     // navigate("/");
    //                     setTab(5)
    //                 }, 5000);
    //             } else {
    //                 toast.error(result.data.message);
    //             }
    //         })
    //         .catch((error) => {
    //             toast.error(error.response.data.message ?? error.response.message);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };

    const handlePrint = () => {
        const inputed = document.getElementById('capture'); // Replace 'capture' with your element ID

        if (!inputed) {
            console.error('Element with ID "capture" not found in the DOM.');
            return;
        }



        html2canvas(inputed, { scrollY: -window.scrollY }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'pt', 'a4');
            const imgWidth = 595.28;
            const imgHeight = canvas.height * imgWidth / canvas.width;

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            // pdf.save(`${input.fullname}-application slip`);
        }).catch((error) => {
            console.error('Error capturing element:', error);
        });
    };

    if (portalData.open === "no") {
        return (
            <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
                <p className="font-bold flex items-center">
                    <i className="fas fa-info-circle mr-2"></i>
                    Information
                </p>
                <p>Printing of Certificate is closed at this moment.</p>
            </div>
        )
    }



    return (
        <div id="capture" className="w-full h-full min-h-full py-6  md:px-4">
            <div className="flex flex-col space-y-3">
                <div className="flex items-center justify-center">
                    <p className="text-2xl font-bold text-dark">Verify Loan Selection</p>
                </div>

                <div className="flex flex-col space-y-2">
                    <p>Enter your PSN Or PLA Number to verify your Identity</p>
                    {error ? (
                        <>
                            <div className="flex items-center text-red-500 text-lg"> {/* Adjust text-lg for larger or smaller text */}
                                <FaTimesCircle className="mr-2" size={24} /> {/* Adjust size as needed */}
                                <p>{error}</p>
                            </div>
                        </>
                    ) : null}
                    <div className="flex flex-col md:flex-row space-x-4">
                        <div>
                            <Input
                                type="text"
                                name="psn"
                                placeholder="Enter PSN or PLA No"
                                onChange={(e) => {
                                    setPsn({ ...psn, psn: e.target.value });
                                }}
                                value={psn.psn}
                            />
                        </div>
                        <div className="mt-2">
                            <SubmitButton
                                disabled={psn.psn === "" || (!portalData && (Object.keys(portalData).length === 0 && portalData.constructor === Object))}
                                onClick={!verifying ? psn.psn.startsWith("PLA") ? getPla : getStaff : null}
                            >
                                {verifying ? (
                                    <ClipLoader color="#fff" size={30} data-testid="loader" />
                                ) : (
                                    "Verify"
                                )}
                            </SubmitButton>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Apply;
