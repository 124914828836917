// import React from 'react'
const IMG_PATH = '/img/logo/'
const Logo = () => {
  return (
    <div className="py-5">
      <img className="md:w-80 md:h-60" src={`${IMG_PATH}jig-nobg.png`} alt="logo" />
    </div>
  )
}

export default Logo