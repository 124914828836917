import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
const Loading = () => {
    return (
        <div className="flex flex-row sapce-x-5 justify-center">
            <div>
                <ClipLoader color="#00BC74" size={50} data-testid="loader" />
            </div>
            <div>
                <ClipLoader color="#00BC74" size={50} data-testid="loader" />
            </div>
            <div>
                <ClipLoader color="#00BC74" size={50} data-testid="loader" />
            </div>
        </div>
    );
};

export default Loading;
