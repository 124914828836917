import React, { useState } from 'react';
import Input from "../../../../components/controls/Input";
import SubmitButton from "../../../../components/controls/SubmitButton";
import { adminService } from '../../../../services/admin/admin.service';
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from 'xlsx';

const Form = ({ onClose }) => {
  const [tab, setTab] = useState(1);
  const [form, setForm] = useState({
    fullname: "",
    psn: "",
    ipps_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [uploadData, setUploadData] = useState({ staff: [] });

  const changeTab = (t) => {
    setTab(t);
  };

  const createUser = () => {
    setLoading(true);
    adminService
      .createApprovedStaff(form)
      .then((result) => {
        if (result && result.data.success) {
          toast.success(result.data.message);
          onClose();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Something went wrong!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Assuming the first row is the header
      const headers = json[0].map(header => header.trim().toUpperCase());
      const staffData = json.slice(1).map(row => {
        let staffObj = {};
        row.forEach((value, index) => {
          // const header = headers[index];
          // if (header === "plaNumber") {
          //   staffObj.plaNumber = value;
          // } else if (header === "fullname") {
          //   staffObj.fullname = value;

          // } else if (header === "designation") {
          //   staffObj.designation = value;

          // }
          // else if (header === "portfolio") {
          //   staffObj.portfolio = value;
          // }
          // else if (header === "cropType") {
          //   staffObj.cropType = value
          // }
          // else if (header === "phoneNumber") {
          //   staffObj.phoneNumber = value
          // }
          // else if (header === "farmLga") {
          //   staffObj.farmLga = value
          // }
          // else if (header === "farmSize") {
          //   staffObj.farmSize = value
          // }
          const header = headers[index];
          if (header === "PLANUMBER") {
            staffObj.plaNumber = value;
          } else if (header === "FULLNAME") {
            staffObj.fullname = value;
          } else if (header === "DESIGNATION") {
            staffObj.designation = value;
          } else if (header === "PORTFOLIO") {
            staffObj.portfolio = value;
          } else if (header === "CROPTYPE") {
            staffObj.cropType = value;
          } else if (header === "PHONENUMBER") {
            staffObj.phoneNumber = value;
          } else if (header === "FARMLGA") {
            staffObj.farmLga = value;
          } else if (header === "FARMSIZE") {
            staffObj.farmSize = value;
          }
          // });
        });
        return staffObj;
      });

      console.log(JSON.stringify({ staff: staffData }));
      setUploadData({ staff: staffData });
    };
    reader.readAsArrayBuffer(file);
  };


  const upload = () => {
    setLoading(true);
    adminService
      .uploadPlaApplicants(uploadData)
      .then((result) => {
        if (result && result.data.success) {
          toast.success(result.data.message);
          onClose();
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Something went wrong!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="w-full px-5">
      <div className="flex flex-row space-x-4 justify-center">
        <div onClick={() => changeTab(1)} className={`font-bold text-md cursor-pointer ${tab === 1 ? "text-dark border-b-2 border-dark" : "text-secondary"}`}>
          <p>Individual</p>
        </div>
        <div onClick={() => changeTab(2)} className={`font-bold text-md cursor-pointer ${tab === 2 ? "text-dark border-b-2 border-dark" : "text-secondary"}`}>
          <p>Bulk</p>
        </div>
      </div>
      <div className="">
        <p className="text-xl text-center font-semibold">Add Employee</p>
      </div>
      {tab === 1 ? (
        <>
          <div className="py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Input
                  type="text"
                  name="fullname"
                  label="Name"
                  placeholder="Name"
                  onChange={(e) => {
                    setForm({ ...form, fullname: e.target.value });
                  }}
                  value={form?.fullname}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="psn"
                  label="PSN NO"
                  placeholder="PSN NO"
                  onChange={(e) => {
                    setForm({ ...form, psn: e.target.value });
                  }}
                  value={form?.psn}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="ipps_id"
                  label="IPPS ID"
                  placeholder="IPPS ID"
                  onChange={(e) => {
                    setForm({ ...form, ipps_id: e.target.value });
                  }}
                  value={form?.ipps_id}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="gradeLevel"
                  label="Grade Level"
                  placeholder="Grade Level"
                  onChange={(e) => {
                    setForm({ ...form, gradeLevel: e.target.value });
                  }}
                  value={form?.gradeLevel}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="mda"
                  label="MDA"
                  placeholder="MDA"
                  onChange={(e) => {
                    setForm({ ...form, mda: e.target.value });
                  }}
                  value={form?.mda}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="bvn"
                  label="BVN"
                  placeholder="BVN"
                  onChange={(e) => {
                    setForm({ ...form, bvn: e.target.value });
                  }}
                  value={form?.bvn}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="bank"
                  label="Salary Bank"
                  placeholder="Salary Bank"
                  onChange={(e) => {
                    setForm({ ...form, bank: e.target.value });
                  }}
                  value={form?.bank}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="accountNo"
                  label="Account No"
                  placeholder="Account No"
                  onChange={(e) => {
                    setForm({ ...form, accountNo: e.target.value });
                  }}
                  value={form?.accountNo}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="nin"
                  label="NIN"
                  placeholder="NIN"
                  onChange={(e) => {
                    setForm({ ...form, nin: e.target.value });
                  }}
                  value={form?.nin}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="cropType"
                  label="Crop Type"
                  placeholder="Crop Type"
                  onChange={(e) => {
                    setForm({ ...form, cropType: e.target.value });
                  }}
                  value={form?.cropType}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Phone Number"
                  onChange={(e) => {
                    setForm({ ...form, phoneNumber: e.target.value });
                  }}
                  value={form?.phoneNumber}
                />
              </div>
              <div>
                <Input
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                  }}
                  value={form?.email}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="lgaOrigin"
                  label="LGA of Origin"
                  placeholder="LGA of Origin"
                  onChange={(e) => {
                    setForm({ ...form, lgaOrigin: e.target.value });
                  }}
                  value={form?.lgaOrigin}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="farmLga"
                  label="Farm LGA"
                  placeholder="Farm LGA"
                  onChange={(e) => {
                    setForm({ ...form, farmLga: e.target.value });
                  }}
                  value={form?.farmLga}
                />
              </div>
              <div>
                <Input
                  type="text"
                  name="farmLocation"
                  label="Farm Location"
                  placeholder="Farm Location"
                  onChange={(e) => {
                    setForm({ ...form, farmLocation: e.target.value });
                  }}
                  value={form?.farmLocation}
                />
              </div>


              <div className="md:col-span-2">
                <SubmitButton
                  onClick={!loading ? createUser : null}
                >
                  {loading ? (
                    <ClipLoader
                      color="#fff"
                      size={30}
                      data-testid="loader"
                    />
                  ) : (
                    "Submit"
                  )}
                </SubmitButton>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="py-4">
            <div className="flex flex-col space-y-5">
              <p>Upload an excel file of the list.</p>
              <div>
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                />
              </div>
              <div className="">
                <SubmitButton
                  onClick={!loading ? upload : null}
                >
                  {loading ? (
                    <ClipLoader
                      color="#fff"
                      size={30}
                      data-testid="loader"
                    />
                  ) : (
                    "Submit"
                  )}
                </SubmitButton>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Form;
