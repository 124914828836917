import Home from "../../pages/Home";
import Dashboard from "../../pages/admin/dashboard";
import Report from "../../pages/admin/report";
import ReportDetails from "../../pages/admin/report/ReportDetails";
import Portal from "../../pages/admin/portal";
import Users from "../../pages/admin/users";
import Staffs from "../../pages/admin/staff";
import ApprovedApplicants from "../../pages/admin/approved-applicants";
import Pla from "../../pages/admin/pla";
import ViewApplicantCertificate from "../../pages/admin/approved-applicants/ApplicantCertificate";
import ViewPlaCertificate from "../../pages/admin/pla/ViewPlaCertificate";


let routes = [
  {
    path: `/`,
    component: Home,
    protected: true
  },
  {
    path: `/dashboard`,
    component: Dashboard,
    protected: true
  },
  {
    path: `/users`,
    component: Users,
    protected: true
  },
  {
    path: `/portal`,
    component: Portal,
    protected: true
  },
  {
    path: `/staffs`,
    component: Staffs,
    protected: true
  },
  {
    path: `/reports`,
    component: Report,
    protected: true
  },
  {
    path: `/sucessful-applicants`,
    component: ApprovedApplicants,
    protected: true
  },
  {
    path: `/pla`,
    component: Pla,
    protected: true
  },
  {
    path: `/view-cert`,
    component: ViewApplicantCertificate,
    protected: true
  },
  {
    path: `/view-placert`,
    component: ViewPlaCertificate,
    protected: true
  },
  {
    path: `/report/:id`,
    component: ReportDetails,
    protected: true
  },

];

export default routes;