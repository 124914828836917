import React, { useState, useEffect } from "react";
import { adminService } from "../../../../services/admin/admin.service";
import { TbReportAnalytics } from "react-icons/tb";
import { HiOutlineUsers } from "react-icons/hi2";
import MainCard from "../../../../components/cards/MainCard";
import Loading from "../../../../components/loader/Loading";
const DashboardHeader = () =>{
    const [data, setTableState] = useState({}); 
    const [isLoading, setIsLoading] = useState(false);
    // Set initial state as an empty array
    const getAllReports = () => {
      setIsLoading(true)
      adminService
        .getStatistics()
        .then((result) => {
          if (result?.data) {
            // alert(JSON.stringify(result?.data));
            setTableState(result.data); // Update tableState with the fetched data array
          } else {
            console.error("Data structure is not as expected or empty.");
            setTableState({}); // Set an empty array if data structure is not as expected or empty
          }
        })
        .catch((error) => {
          console.error("Error fetching employee data:", error);
          setTableState([]); // Set an empty array or handle the error case accordingly
        }).finally(()=>{
          setIsLoading(false)
        });
    };
    useEffect(() => {
      getAllReports();
    }, []);
return(
    <>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <MainCard>
            <div className="flex flex-col space-y-4 p-3">
                <p>Total Employee</p>
                <div className="flex flex-row justify-between">
                {isLoading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
                <p className="text-lg md:text-2xl font-bold">{data?.totalStaff}</p>
                </> )}
                <HiOutlineUsers size={30} className="text-primary" />
                </div>
               
            </div>
        </MainCard>
        <MainCard>
            <div className="flex flex-col space-y-4 p-3">
                <p>Total Applications</p>
                <div className="flex flex-row justify-between">
                {isLoading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
                <p className="text-lg md:text-2xl font-bold">{data?.totalApplications}</p>
                </> )}
                <TbReportAnalytics size={30} className="text-primary" />
                </div>
               
            </div>
        </MainCard>
       
    </div>
    </>
)
}
export default DashboardHeader