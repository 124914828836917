import { util } from "../../constants/api.constant";
import { http } from "../../constants/http";

let authService = {
  login: async (body) => {
    try {
      const response = await http.post(`${util.baseUrl}auth/login`, body, {
        headers: util.getHeaders(),
      });
      return response;
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  },
  
  createUser: async (body) => {
    try {
      const response = await http.post(`${util.baseUrl}auth/create`, body, {
        headers: util.getHeaders(),
      });
      return response;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  },
  
  deleteUser: async (id) => {
    try {
      const response = await http.put(`${util.baseUrl}user/delete/${id}`, null, {
        headers: util.getAuthorizedHeaders(),
      });
      return response;
    } catch (error) {
      console.error(`Error deleting user with ID ${id}:`, error);
      throw error;
    }
  },
  
  getAll: async () => {
    try {
      const response = await http.get(`${util.baseUrl}user/`, {
        headers: util.getAuthorizedHeaders(),
      });
      return response;
    } catch (error) {
      console.error('Error getting all users:', error);
      throw error;
    }
  },
};

export { authService };
