import React, { useState, useEffect } from "react";
import { adminService } from "../../../../services/admin/admin.service";
import { toast } from "react-toastify";
import Loading from "../../../../components/loader/Loading";
import { useNavigate } from "react-router-dom";
const UsersTable = () => {
  const [data, setTableState] = useState([]);
  const [filterPsn, setFilterPsn] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Set initial state as an empty array
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 200;
  const navigate = useNavigate();

  const getStudent = () => {
    setIsLoading(true);
    adminService.getAllPlaStaff().then((result) => {
      if (Array.isArray(result?.data?.data)) {
        setTableState(result.data.data); // Update tableState with the fetched data array
      } else {
        console.error('Data structure is not as expected or empty.');
        setTableState([]); // Set an empty array if data structure is not as expected or empty
      }
    }).catch((error) => {
      console.error('Error fetching applicants data:', error);
      setTableState([]); // Set an empty array or handle the error case accordingly
    }).finally(() => {
      setIsLoading(false)
    });
  };

  // Function to handle the change in the input field
  const handleFilterChange = (e) => {
    setFilterPsn(e.target.value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  // Filtered data based on PSN
  const filteredData = data.filter((stud) => stud.plaNumber && stud.plaNumber.includes(filterPsn));

  // Calculate total pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const openModal = (plaNumber) => {
    const getStaff = data.filter((staff) => staff.plaNumber === plaNumber);
    console.log(getStaff);
    setSelectedStaff(getStaff[0]);
    setIsModalOpen(true);
  };
  const viewCertificate = (plaNumber) => {
    if (!plaNumber) {
      toast.error("There was an error getting the certificate");
      return;
    }

    navigate("/view-placert", { state: { plaValue: plaNumber } })
  };

  const updateDisbursement = (status) => {
    adminService.updatePlaDisbursement(selectedStaff.plaNumber, status).then((result) => {
      toast.success('Disbursement status updated successfully');
      getStudent()
      const updatedStaff = { ...selectedStaff, disbursement: status };
      setSelectedStaff(updatedStaff);
    }).catch((error) => {
      console.error('Error updating disbursement status:', error);
      toast.error('Error updating disbursement status');
    });
    // setIsModalOpen(false);
  };

  useEffect(() => {
    getStudent();
  }, []);
  return (
    <div className="w-full">
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-full max-w-4xl shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Staff Details</h3>
              <div className="mt-2 px-7 py-3">
                <div className="mt-3 text-center">
                  <button
                    className="bg-blue-700 text-white font-bold py-2 px-4 rounded hover:bg-blue-800 focus:outline-none focus:shadow-outline transform transition duration-150 ease-in-out"
                    onClick={() => viewCertificate(selectedStaff.plaNumber)}
                  >
                    View Certificate
                  </button>
                  <div className="mt-2 px-7 py-3 bg-white shadow rounded-lg">
                    <div className="grid grid-cols-2 gap-4">
                      <p className="text-sm text-gray-600">Full Name: <span className="text-gray-900 font-semibold">{selectedStaff?.fullname}</span></p>
                      <p className="text-sm text-gray-600">PLA NO: <span className="text-gray-900 font-semibold">{selectedStaff?.plaNumber}</span></p>
                      <p className="text-sm text-gray-600">Designation: <span className="text-gray-900 font-semibold">{selectedStaff?.designation}</span></p>
                      <p className="text-sm text-gray-600">Portfolio: <span className="text-gray-900 font-semibold">{selectedStaff?.portfolio}</span></p>
                      <p className="text-sm text-gray-600">Crop Type: <span className="text-gray-900 font-semibold">{selectedStaff?.cropType}</span></p>
                      <p className="text-sm text-gray-600">Phone Number: <span className="text-gray-900 font-semibold">{selectedStaff?.phoneNumber}</span></p>
                      <p className="text-sm text-gray-600">Farm LGA: <span className="text-gray-900 font-semibold">{selectedStaff?.farmLga}</span></p>
                      <p className="text-sm text-gray-600">Farm Location: <span className="text-gray-900 font-semibold">{selectedStaff?.farmLga}</span></p>
                      <p className="text-sm text-gray-600">Issue Date: <span className="text-gray-900 font-semibold">{selectedStaff?.issueDate ? new Date(selectedStaff.issueDate).toLocaleDateString() : 'N/A'}</span></p>
                    </div>
                  </div>
                  <p className="text-l font-bold p-4 text-black">
                    Disbursement:<span className={`px-2 py-1 rounded text-white ${selectedStaff?.disbursement ? 'bg-green-500' : 'bg-red-500'}`}>
                      {selectedStaff?.disbursement ? 'Disbursed' : 'Not yet Disbursed'}
                    </span>
                  </p>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
                    onClick={() => updateDisbursement(!selectedStaff.disbursement)}
                  >
                    Toggle Disbursement
                  </button>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      )}
      <div className="py-2">
        <label htmlFor="psn-filter" className="block text-sm font-medium text-gray-700">Filter by PSN:</label>
        <input
          type="text"
          id="psn-filter"
          value={filterPsn}
          onChange={handleFilterChange}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          placeholder="Enter PSN number"
        />
      </div>

      <div className="py-4 responsive overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SN</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">PLA No</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Disbursment Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Designation</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Portfolio</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Farm LGA</th>


            </tr>
          </thead>
          {isLoading ? (
            <>
              <Loading />
            </>
          ) : (
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((stud, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {stud.fullname}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {stud.plaNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 rounded text-white ${stud.disbursement ? 'bg-green-500' : 'bg-red-500'}`}>
                      {stud.disbursement ? 'Disbursed' : 'Not yet Disbursed'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {stud.designation}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {stud.portfolio}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {stud.farmLga}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => openModal(stud.plaNumber)}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
              {currentItems.length === 0 && (
                <tr>
                  <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-center text-gray-500">No employee found</td>
                </tr>
              )}
            </tbody>
          )}
        </table>
        <div className="pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            style={{ border: 'none', padding: '10px 15px', margin: '0 5px', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#007bff', color: 'white', fontWeight: 'bold' }}
          >
            Previous
          </button>
          {currentPage > 3 && (
            <>
              <button
                onClick={() => paginate(1)}
                style={{ border: 'none', padding: '5px 10px', margin: '0 5px', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#007bff', color: 'white' }}
              >
                1
              </button>
              <span style={{ margin: '0 5px' }}>...</span>
            </>
          )}
          {Array.from({ length: totalPages }, (_, i) => i + 1)
            .filter(number => number >= currentPage - 2 && number <= currentPage + 2)
            .map(number => (
              <button
                key={number}
                onClick={() => paginate(number)}
                disabled={currentPage === number}
                style={{ border: 'none', padding: '5px 10px', margin: '0 5px', borderRadius: '5px', cursor: 'pointer', backgroundColor: currentPage === number ? '#0069d9' : '#007bff', color: 'white' }}
              >
                {number}
              </button>
            ))}
          {currentPage < totalPages - 2 && (
            <>
              <span style={{ margin: '0 5px' }}>...</span>
              <button
                onClick={() => paginate(totalPages)}
                style={{ border: 'none', padding: '5px 10px', margin: '0 5px', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#007bff', color: 'white' }}
              >
                {totalPages}
              </button>
            </>
          )}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{ border: 'none', padding: '10px 15px', margin: '0 5px', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#007bff', color: 'white', fontWeight: 'bold' }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};


export default UsersTable;
