import { util } from "../../constants/api.constant";
import { http } from "../../constants/http";

let applyService = {
  apply: async (body) => {
    try {
      const response = await http.post(`${util.baseUrl}apply`, body, {
        headers: util.getAuthorizedHeaders(),
      });
      return response;
    } catch (error) {
      console.error('Error applying:', error);
      throw error; // Throw the error for it to be caught in the calling function
    }
  },

  getStaffByPsn: async (psn) => {
    try {
      const response = await http.get(`${util.baseUrl}staff/psn/${psn}`, {
        headers: util.getHeaders(),
      });
      return response;
    } catch (error) {
      console.error(`Error getting staff by PSN ${psn}:`, error);
      throw error; // Throw the error for it to be caught in the calling function
    }
  },
  getApprovedStaffByPsn: async (psn) => {
    try {
      const response = await http.get(`${util.baseUrl}approved/psn/${psn}`, {
        headers: util.getHeaders(),
      });
      return response;
    } catch (error) {
      console.error(`Error getting staff by PSN ${psn}:`, error);
      throw error; // Throw the error for it to be caught in the calling function
    }
  },
  getPlaStaffByPla: async (pla) => {
    try {
      const response = await http.get(`${util.baseUrl}pla/pla/${pla}`, {
        headers: util.getHeaders(),
      });
      return response;
    } catch (error) {
      console.error(`Error getting staff by PSN ${pla}:`, error);
      throw error; // Throw the error for it to be caught in the calling function
    }
  },
};

export { applyService };
