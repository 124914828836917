import React, { useState, useEffect } from "react";
import { adminService } from "../../../services/admin/admin.service";
import { useParams } from "react-router-dom";
const ReportDetails = () => {
  const { id } = useParams();
  const [data, setTableState] = useState({}); // Set initial state as an empty array
  const getAllReports = () => {
    adminService
      .getApplicationById(id)
      .then((result) => {
        console.log(result?.data.data);
        setTableState(result.data.data); // Update tableState with the fetched data array
      })
      .catch((error) => {
        console.error("Error fetching employee data:", error);
        setTableState([]); // Set an empty array or handle the error case accordingly
      });
  };
  useEffect(() => {
    getAllReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="p-6">
      <div className="">
        <p className="text-xl text-gray-500 font-semibold">
          Application Details
        </p>
      </div>

      <div className="p-3 bg-white my-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant Name: </p>
            <p className="text-md">{data.fullname}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">
              Applicant PSN NO:{" "}
            </p>
            <p className="text-md">{data.psn}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant Phone: </p>
            <p className="text-md">{data.phone}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant Email: </p>
            <p className="text-md">{data.email}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant Gender: </p>
            <p className="text-md">{data.gender}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant BVN: </p>
            <p className="text-md">{data.bvn}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant NIN: </p>
            <p className="text-md">{data.nin}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant Bank: </p>
            <p className="text-md">{data.bank}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">
              Applicant Account Number:{" "}
            </p>
            <p className="text-md">{data.accountNo}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">
              Applicant Grade Level:{" "}
            </p>
            <p className="text-md">{data.gradeLevel}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant MDA: </p>
            <p className="text-md">{data.mda}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Appointment Type: </p>
            <p className="text-md">{data.appointmentType}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant LGA: </p>
            <p className="text-md">{data.lga}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applicant State: </p>
            <p className="text-md">{data.state}</p>
          </div>
         
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Farm Location: </p>
            <p className="text-md">{data.farmLoc}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Crop Type: </p>
            <p className="text-md">{data.cropType}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Community: </p>
            <p className="text-md">{data.community}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Farm Ward: </p>
            <p className="text-md">{data.farmWard}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Farm LGA: </p>
            <p className="text-md">{data.farmLga}</p>
          </div>
          <div className="flex flex-row space-x-4">
            <p className="text-dark text-md font-semibold">Applied Date: </p>
            <p className="text-md">{data.createdAt}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetails;
