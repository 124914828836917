/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaTimesCircle } from 'react-icons/fa';
import ReactToPrint from 'react-to-print';
import Certificate from '../../../components/Certificate';
import { toast } from "react-toastify";

import { applyService } from "../../../services/apply/apply.service";


function ViewApplicantCertificate() {
    const certificateRef = useRef();
    const [error, setError] = useState("");
    const [applicant, setApplicant] = useState({
        id: "null",
        name: "null",
        psn: "null",
        gradeLevel: "null",
        cropType: "null",
        phoneNumber: "null",
        email: "null",
        nin: "null",
        bank: "null",
        accountNumber: "null",
        mdaLga: "null",
        lgaOrigin: "null",
        farmLga: "null",
        farmLocation: "null",
        issueDate: "null",
    });
    // Inside your component
    const location = useLocation();
    const { psnValue } = location.state || {}; // Ensure to handle the case where state or psn might be undefined


    const getStaff = () => {
        applyService
            .getApprovedStaffByPsn(psnValue)
            .then((result) => {
                if (result && result.data.success) {
                    setApplicant({
                        id: result.data.data.id,
                        name: result.data.data.fullname,
                        psn: result.data.data.psn,
                        gradeLevel: result.data.data.gradeLevel,
                        cropType: result.data.data.cropType,
                        phoneNumber: result.data.data.phoneNumber,
                        email: result.data.data.email,
                        nin: result.data.data.nin,
                        bank: result.data.data.bank,
                        accountNumber: result.data.data.accountNo,
                        mdaLga: result.data.data.mda,
                        lgaOrigin: result.data.data.lgaOrigin,
                        farmLga: result.data.data.farmLga,
                        farmLocation: result.data.data.farmLocation,
                        issueDate: result.data.data.updatedAt,
                    })

                } else {
                    setError("Employee Not Approved...");
                    toast.error("Employee Not Approved...");
                }
            })
            .catch((error) => {
                console.error("Error response:", error.response); // Add logging for debugging
                if (error.response && error.response.status === 404) {
                    setError("Employee Not Approved...");
                    toast.error(error.response?.data?.message ?? "Employee Not Approved...");
                } else {
                    toast.error(error.response?.data?.message ?? error?.message);
                }
            })
    };

    useEffect(() => {
        getStaff();
    }, [psnValue])

    return (
        error ? (
            <div className='h-fit min-h-[50vh]'>
                <div className="error-message flex items-center text-red-600">
                    <FaTimesCircle size={24} className="mr-2" />
                    <span>Employee with PSN number not approved</span>
                </div>
            </div>
        ) : (
            <div className="flex flex-col items-center justify-center min-h-screen h-fit p-4 relative">

                <div className="mb-8 p-4 border relative border-gray-300 rounded shadow overflow-hidden h-full w-full max-w-4xl">
                    <Certificate ref={certificateRef} applicant={applicant} />
                </div>
                <ReactToPrint
                    trigger={() => <button className="mt-4 p-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors duration-200 ease-in-out">Print Certificate</button>}
                    content={() => certificateRef.current}
                />
            </div>

        )
    );
}

export default ViewApplicantCertificate;
